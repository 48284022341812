import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../app/store';
import { UserInfoV1 } from '../../models';
import { CheckInToHabitParams, Api } from '../../api';

const initialState: UserInfoV1 = <UserInfoV1>{};

class OptimisticApi {
}

export const goalsSlice = createSlice({
  name: 'goals',
  initialState,
  reducers: {
    // todo: update local store.
    initializeGoals: (_, action: PayloadAction<UserInfoV1>) => action.payload,
    checkInToHabit: (state, action: PayloadAction<CheckInToHabitParams>) => {
      Api.checkInToHabit(action.payload);
      return state;
    },
    uncheckInToHabit: (state, action: PayloadAction<{ habitId: string, date: number }>) => {
      Api.uncheckInToHabit(action.payload.habitId, action.payload.date);
      return state;
    },
    updateHabitOrdering: (state, action: PayloadAction<string[]>) => {
      Api.updateHabitOrdering(action.payload);
      return state;
    },
    updateGoalOrdering: (state, action: PayloadAction<string[]>) => {
      Api.updateGoalOrdering(action.payload);
      return state;
    },
  },
});

export const { initializeGoals, checkInToHabit, uncheckInToHabit, updateHabitOrdering, updateGoalOrdering } = goalsSlice.actions;

export const initializeAsync = (userInfo: UserInfoV1): AppThunk => dispatch => {
  setTimeout(() => {
    dispatch(initializeGoals(userInfo));
  }, 1000);
};

export const selectGoals = (state: RootState) => state.goals

export default goalsSlice.reducer;
