import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import goalsReducer from "./reducers/goals_reducer";
import prioritiesReducer from "./reducers/priorities_reducer";
import profileReducer from "./reducers/profile_reducer";

export const store = configureStore({
  reducer: {
    goals: goalsReducer,
    priorities: prioritiesReducer,
    profile: profileReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
