export const Colors = {
  primaryBlue: "#4B4BFF",
  primaryNavy: "#072DAC",
  dangerColor: "#E10F0F",
  bodyBlack: "#23253D",
  bodySilver: "#DCDDE5",
  buttonGreen: "#0DBB8A",
  habitTrack: "#EAE9EE",
  offWhite: "#F3F3F3",
  offBlack: "#1B1C30",
  labelPurple: "#6F71FE",
  buttonMute: "#9BA7BC",
  appBlack: "#121322",
  appWhite: "white",
  softWarning: "#FF4C4C",
};
