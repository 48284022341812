import React from "react";
import { UserInfoPresenter } from "./presenters";
import { TodosV2 } from "./models";

export interface WithUserInfo {
  userInfo?: UserInfoPresenter;
}

export const { Provider: UserInfoProvider, Consumer: UserInfoConsumer } = React.createContext<UserInfoPresenter | null>(
  null
);

export interface WithUserTodos {
  todos?: TodosV2;
}

export const { Provider: UserPrioritiesProvider, Consumer: UserPrioritiesConsumer } = React.createContext<TodosV2 | null>(
  null
);

export type BooleanCallback = (selected: boolean) => void;
