import React from "react";
import { Carousel } from "react-responsive-carousel";
import { RouteComponentProps } from "@reach/router";
import { AccomplishmentPresenter, MindsetPresenter } from "../presenters";
import { WithUserInfo, UserInfoConsumer } from "../interfaces";

import "./mindsets.sass";

const Images: string[] = [
  "bg-water.jpg",
  "bg-wall.jpg",
  "bg-purple-lake.jpg",
  "bg-sand.jpg",
  "bg-dew.jpg",
  "bg-cloud.jpg",
  "bg-sun.jpg",
  "bg-moon.jpg",
  "bg-travelplan.jpg",
  "bg-coffee.jpg",
  "bg-adventure.jpg",
  "bg-dream.jpg",
  "bg-fuji.jpg",
  "bg-snownight.jpg",
  "bg-mountains.jpg",
  "bg-space.jpg",
  "bg-peak.jpg",
  "bg-sunset.jpg",
  "bg-fairy.jpg",
  "bg-cloudcandy.jpg",
  "bg-cloudsea.jpg",
  "bg-color-cloud.jpg",
  "bg-endure.jpg",
  "bg-flower.jpg",
  "bg-bushes.jpg",
  "bg-green.jpg",
  "bg-grid.jpg",
  "bg-focus.jpg",
  "bg-concrete.jpg",
  "bg-repeat.jpg",
  "bg-metal.jpg",
  "bg-rock.jpg",
  "bg-modern.jpg",
  "bg-stripe.jpg",
  "bg-paper.jpg",
  "bg-neon.jpg",
  "bg-oil.jpg",
  "bg-bubble.jpg",
  "bg-paint.jpg",
  "bg-opal.jpg",
  "bg-yellow.jpg",
  "bg-jelly.jpg",
  "bg-sparkle.jpg",
  "bg-frozen.jpg",
  "bg-village.jpg",
  "bg-stone.jpg",
  "bg-lamps.jpg",
  "bg-hole.jpg",
  "bg-reflect.jpg",
  "bg-pier.jpg",
  "bg-aqua.jpg",
  "bg-cliff.jpg",
  "bg-boats.jpg",
  "bg-ballons.jpg",
  "bg-slope.jpg",
  "bg-spiral.jpg",
  "bg-door.jpg",
  "bg-steps.jpg",
];

function getImages(index: number): string {
  const image = Images[(index | 0) % Images.length];
  return `https://static.mindfulsuite.com/bcg/${image}`;
}

function VisionBoard({ items }: { items: (AccomplishmentPresenter | MindsetPresenter)[] }) {
  return (
    <div className="mindsets vision-board">
      {items.map((item, index) => {
        return (
          <div className="mindset" key={index} style={{ backgroundImage: `url(${item.url ?? getImages(index)})` }}>
            <div className="content">
              <h3>{item.content}</h3>
            </div>
          </div>
        );
      })}
    </div>
  );
}

function SlideShow({ items }: { items: (AccomplishmentPresenter | MindsetPresenter)[] }) {
  return (
    <Carousel
      className="mindsets"
      showArrows={false}
      showStatus={false}
      autoPlay
      infiniteLoop
      swipeable
      interval={8000}
      transitionTime={500}
      showThumbs={false}
      centerMode
      centerSlidePercentage={40}
    >
      {items.map((item, index) => {
        return (
          <div className="mindset" key={index} style={{ backgroundImage: `url(${item.url ?? getImages(index)})` }}>
            <div className="content">
              <h3>{item.content}</h3>
            </div>
          </div>
        );
      })}
    </Carousel>
  );
}

export default function Mindsets(props: { visionBoard?: boolean } & WithUserInfo & RouteComponentProps) {
  return (
    <UserInfoConsumer>
      {(userInfo) => {
        const info = props.userInfo ?? userInfo;
        const items: (AccomplishmentPresenter | MindsetPresenter)[] = (info?.accomplishments ?? []).concat(
          info?.mindsets ?? []
        );
        if (!items || items.length === 0) {
          return <div>empty</div>;
        }
        return (
          <div className="mindsets-section">
            {!props.visionBoard && <SlideShow items={items} />}
            {props.visionBoard && <VisionBoard items={items} />}
          </div>
        );
      }}
    </UserInfoConsumer>
  );
}
