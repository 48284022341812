import React, { FormEvent, useRef, KeyboardEvent } from "react";
import classnames from "classnames";

import DarkUndone from "../img/check-undone-dark.svg";
import DarkSelected from "../img/check-select-dark.svg";
import DarkChecked from "../img/check-done-dark.svg";

import { StepV1 } from "../models";
import { BooleanCallback } from "../interfaces";

import "./list.sass";

export function ListItem({
  task,
  isSelect,
  onToggle,
}: {
  task: StepV1;
  isSelect?: boolean;
  onToggle: BooleanCallback;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const isDone = task.complete ?? false;
  const className = classnames({
    "task-list__item": true,
    "task-list__item-crossed": isDone,
  });

  function onInput(event: FormEvent) {
    // console.log(ref.current?.innerHTML);
  }

  function onBlur(event: FormEvent) {
    console.log(event);
  }

  function onFocus(event: FormEvent) {
    console.log(event);
  }

  function onKeyUp(event: KeyboardEvent<HTMLDivElement>) {
    console.log(ref.current?.innerText);
    console.log(event.key);
  }

  return (
    <div className={className}>
      <img
        onClick={() => onToggle(!isDone)}
        className="task-list__item__toggle"
        src={isDone ? (isSelect ? DarkSelected : DarkChecked) : DarkUndone}
      />
      <div
        ref={ref}
        contentEditable
        tabIndex={0}
        className="task-list__item__content"
        onInput={onInput}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyUp={onKeyUp}
      >
        {task.content}
      </div>
    </div>
  );
}
