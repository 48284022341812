
import { format } from "date-fns";
import { StepV1, TodosV2 } from "./models";
import { timestampDouble } from "./utils";

export class TodoRepo {
  todos: TodosV2;
  preselectedBacklog: Set<string>;
  deviceId: string;

  constructor({ todos, preselectedBacklog, deviceId }: {
    todos: TodosV2, preselectedBacklog: Set<string>, deviceId: string,
  }) {
    this.todos = todos ?? <TodosV2>{
      active: {},
      backlog: [],
      recentlyDeleted: {},
      tasks: {},
      createdAt: timestampDouble(),
      updatedAt: timestampDouble(),
    };
    this.preselectedBacklog = preselectedBacklog ?? new Set<string>();
    this.deviceId = deviceId;
  }

  static active(todos: TodosV2): StepV1[] {
    return [];
  }
  static conceptualDate(date: Date): string { return format(date, "yyyy-MM-dd") };
  static keyToday(): string { return this.conceptualDate(new Date()) };
  // static get keyTomorrow => conceptualDate(safeNextDay(DateTime.now(), 1));

  static updateActiveTodos(todos: TodosV2, tasks: StepV1[]): TodosV2 {
    return todos;
  }
}
