import React, { useRef, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { SortableContainer, SortableElement, SortEnd } from "react-sortable-hoc";
import { v4 as uuidv4 } from "uuid";

import { StepV1, TodosV2, DayTasksV1 } from "../models";
import { RouteComponentProps } from "@reach/router";
import { UserPrioritiesConsumer, WithUserTodos, BooleanCallback } from "../interfaces";
import { useDispatch } from "react-redux";
import { updateGoalOrdering } from "../app/reducers/goals_reducer";

import { ListItem } from "./list";
import { TodoRepo } from "../todo_repo";
import { updateActiveTodos } from "../app/reducers/priorities_reducer";

import "./priorities.sass";
import Paper from "@material-ui/core/Paper";

function safeItems(ids: string[], todos: TodosV2): StepV1[] {
  return ids.map((id) => (todos.tasks ?? {})[id]).filter((t) => Boolean(t));
}

const SortableItem = SortableElement(({ value }: { value: React.ReactElement }) => value);

const SortableList = SortableContainer(({ items }: { items: StepV1[] }) => {
  const dispatch = useDispatch();
  return (
    <div className="task-list">
      {items.map((task, index) => {
        return (
          <SortableItem
            key={`task-${task.id}`}
            index={index}
            value={
              <ListItem
                key={task.id!}
                task={task}
                onToggle={(selected: boolean) => dispatch(updateActiveTodos(items))}
              />
            }
          />
        );
      })}
    </div>
  );
});

function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

export function Active(props: { todos: TodosV2 }) {
  const dispatch = useDispatch();

  function onDragEnd({ oldIndex, newIndex }: SortEnd) {
    const newActive = reorder(active, oldIndex, newIndex);
    // dispatch(updatePrioritiesOrdering(newActive.filter((t) => Boolean(t)).map((t) => t!)));
  }

  const active: string[] = ((props.todos.active ?? {})[TodoRepo.keyToday()] ?? ({} as DayTasksV1)).tasks ?? [];
  const items = safeItems(active, props.todos);

  // const isComplete =

  return (
    <Paper className="priorities-active" elevation={24}>
      <h2>
        <FormattedMessage id="general.priorities" defaultMessage="Today" description="Priorities section title" />
      </h2>
      <SortableList axis="y" distance={items.length <= 1 ? 50000 : 25} onSortEnd={onDragEnd} items={items} />
    </Paper>
  );
}

export default function Priorities(props: WithUserTodos & RouteComponentProps) {
  return (
    <UserPrioritiesConsumer>
      {(userTodos) => {
        return (
          <div className="wrapper">
            <div className="priorities-section">
              <Active todos={userTodos || ({} as TodosV2)} />
            </div>
          </div>
        );
      }}
    </UserPrioritiesConsumer>
  );
}
