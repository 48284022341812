import React, { useRef, useEffect, useState, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { SortableContainer, SortableElement, SortEnd, SortEvent } from "react-sortable-hoc";
import { v4 as uuidv4 } from "uuid";

import { ColorTheme } from "../models";
import { ColorThemeToSwatch } from "../utils";
import { RouteComponentProps } from "@reach/router";
import { GoalPresenter } from "../presenters";
import { WithUserInfo, UserInfoConsumer } from "../interfaces";
import { useDispatch } from "react-redux";
import { updateGoalOrdering } from "../app/reducers/goals_reducer";

import "./goals.sass";

export function Goal({ goal }: { goal: GoalPresenter }) {
  const ref = useRef<HTMLDivElement>(null);
  const [windowWidth, setWindowWidth] = useState(960);
  const [width, setWidth] = useState(680);
  useEffect(() => {
    setWidth(ref.current?.offsetWidth || 0);
  }, [ref, setWidth, windowWidth]);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const percent = goal.completionPercent || 0;
  const ratio = percent / 100.0;

  function bg() {
    const colorTheme = goal.color || ColorTheme.Blue;
    const gradientId = colorTheme;
    const swatch = ColorThemeToSwatch[colorTheme];
    // start from (8, 0)
    // draw horizontal line to progress (x, lastY)
    // draw an arc with 480 as radius if percenrage < 0.99, stop when y hits 108 - the height.
    // draw horizontal to (8, lastY)
    // close the curve with rounded corners
    const clip =
      ratio > 0.99
        ? `M8 0 H${width * ratio - 8} q 8 0 8 8 V100 q 0 8 -8 8 H 8 q -8 0 -8 -8 V 8 q 0 -8 8 -8`
        : `M8 0 H${width * ratio} a 480 480 0 0 1 0 108 H 8 q -8 0 -8 -8 V 8 q 0 -8 8 -8`;

    const clipId = uuidv4();
    return (
      <svg width={width} height="100%">
        <defs>
          <linearGradient id={`grad${gradientId}`} x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" style={{ stopColor: swatch.main, stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: swatch.off, stopOpacity: 1 }} />
          </linearGradient>
          <clipPath id={`clip${clipId}`}>
            <path d={clip} />
          </clipPath>
        </defs>
        <rect
          width={width * ratio + 16}
          height="100%"
          fill={`url(#grad${gradientId})`}
          clipPath={`url(#clip${clipId})`}
        />
      </svg>
    );
  }

  return (
    <div className="goal">
      <div className="goal__base">
        <div className="content">
          <div className="subject">{goal.subject}</div>
          <div className="percent">{percent}%</div>
        </div>
      </div>
      {percent >= 0.5 && (
        <div className="goal__bg" ref={ref}>
          {bg()}
        </div>
      )}
      <div className="goal__top-content" style={{ width: `${ratio * width + 4}px` }}>
        <div className="content" style={{ width }}>
          <h3 className="subject">{goal.subject}</h3>
          <div className="percent">{percent}%</div>
        </div>
      </div>
    </div>
  );
}

const SortableItem = SortableElement(({ value }: { value: React.ReactElement }) => value);

const SortableList = SortableContainer(({ items }: { items: GoalPresenter[] }) => {
  return (
    <div className="goals__list">
      {items.map((goal, index) => (
        <SortableItem key={`goal-${goal.goal!.id}`} index={index} value={<Goal key={goal.goal!.id!} goal={goal} />} />
      ))}
    </div>
  );
});

function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

export function GoalList(props: { goals: GoalPresenter[] }) {
  const dispatch = useDispatch();

  function onDragEnd({ oldIndex, newIndex }: SortEnd) {
    const newGoals = reorder(goals, oldIndex, newIndex);
    dispatch(updateGoalOrdering(newGoals.filter((g) => Boolean(g.goal?.id)).map((g) => g.goal!.id!)));
  }

  const goals = props.goals;

  return <SortableList axis="y" distance={25} onSortEnd={onDragEnd} items={goals} />;
}

export default function Goals(props: WithUserInfo & RouteComponentProps) {
  return (
    <UserInfoConsumer>
      {(userInfo) => {
        return (
          <div className="wrapper">
            <div className="goals-section">
              <h2>
                <FormattedMessage id="general.goals" defaultMessage="Goals" description="Goals section title" />
              </h2>
              <GoalList goals={userInfo?.goals ?? []} />
            </div>
          </div>
        );
      }}
    </UserInfoConsumer>
  );
}
