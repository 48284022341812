import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { safeGetImageURL } from '../../utils';
import { storage } from "../../db";

const PROFILE_PICTURE_KEY = "profile/profile_picture.png";

export interface Profile {
  uid: string | null,
  displayName: string | null,
  defaultProfilePicture: string | null,
  customProfilePicture?: string | null,
}

const initialState: Profile = {} as Profile;

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    initializeProfile: (_, action: PayloadAction<Profile | null>) => {
      if (action.payload) {
        return action.payload;
      }
      return {} as Profile;
    },
    setCustomPicture: (state, action: PayloadAction<string | null>) => {
      state.customProfilePicture = action.payload;
    }
  },
});

export const { initializeProfile, setCustomPicture } = profileSlice.actions;

export const loadProfile = (profile: Profile | null): AppThunk => async dispatch => {
  if (profile && profile.uid) {
    const customPhotoURL = await safeGetImageURL(storage, profile.uid, PROFILE_PICTURE_KEY);
    profile.customProfilePicture = customPhotoURL;
    dispatch(initializeProfile(profile));
  } else {
    dispatch(initializeProfile(null));
  }
};

export const selectProfile = (state: RootState) => state.profile

export default profileSlice.reducer;
