import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../app/store';
import { TodosV2, StepV1 } from '../../models';
import { TodoRepo } from '../../todo_repo';

const initialState: TodosV2 = {} as TodosV2;

export const prioritiesSlice = createSlice({
  name: 'priorities',
  initialState,
  reducers: {
    initializePriorities: (_, action: PayloadAction<TodosV2>) => action.payload,
    updateActiveTodos: (state, action: PayloadAction<StepV1[]>) => {
      return TodoRepo.updateActiveTodos(state, action.payload);
    }
  },
});

export const { initializePriorities, updateActiveTodos } = prioritiesSlice.actions;

export const initializeAsync = (priorities: TodosV2): AppThunk => dispatch => {
  setTimeout(() => {
    dispatch(initializePriorities(priorities));
  }, 1000);
};

export const selectPriorities = (state: RootState) => state.priorities

export default prioritiesSlice.reducer;
