/* eslint:disable */

// To parse this data:
//
//   import { Convert, ActivityType, ObjectType, MainCategory, SecondaryCategory, ColorTheme, GoalUnit, HabitUnit, PeriodUnit, MindsetTheme, SubscriptionMethod, UserInfoVersion, MediaType, MindfulInAppPurchase, TodoCommand, MindfulGoalUser, MindfulTodoUser, TodosV2, DeletedAt, TodosV1, DailyTodoV1, DayTasksV1, UserInfoV1, AccomplishmentV1, ActivityV1, AndroidBehaviorV1, QuestionV1, AnswerV1, DesktopBehaviorV1, GoalV1, HabitV1, CheckInV1, ReminderV1, DeviceBehaviorV1, MediaV1, MindsetV1, ProfileV1, StepV1, SubscriptionV1, UserActivationV1, UpdateUserRequestV1, InitialUserDataV1, PromoCodeV1, CustomizationV1, DeleteOrArchivePayload, UnarchivePayload, UpdateOrderingPayload, AddProgressToGoalPayload, CheckInPayload, UncheckInPayload, CheckInPayloadV2, UncheckInPayloadV2, ValidatePurchasePayload, PurchasedItem, SubscriptionVerificationInfo, ValidatePurchasePayloadV2, ApplyPromoCodePayload, ApplyPromoCodeResponse, VerifyCodePayload, NoPurchaseFoundPayload, TodoAddTasksToDayPayload, TodoUpdateTasksForDayPayload, TodoMoveTasksToDayPayload, TodoAddTasksToBacklogPayload, TodoMoveTasksToBacklogPayload, TodoDeleteTasksFromBacklogPayload, TodoUpdateBacklogOrderingPayload, TodoSetActiveDayPayload, UpdateTodosPayload } from "./file";
//
//   const activityType = Convert.toActivityType(json);
//   const objectType = Convert.toObjectType(json);
//   const mainCategory = Convert.toMainCategory(json);
//   const secondaryCategory = Convert.toSecondaryCategory(json);
//   const colorTheme = Convert.toColorTheme(json);
//   const goalUnit = Convert.toGoalUnit(json);
//   const habitUnit = Convert.toHabitUnit(json);
//   const periodUnit = Convert.toPeriodUnit(json);
//   const mindsetTheme = Convert.toMindsetTheme(json);
//   const subscriptionMethod = Convert.toSubscriptionMethod(json);
//   const userInfoVersion = Convert.toUserInfoVersion(json);
//   const mediaType = Convert.toMediaType(json);
//   const mindfulInAppPurchase = Convert.toMindfulInAppPurchase(json);
//   const todoCommand = Convert.toTodoCommand(json);
//   const mindfulGoalUser = Convert.toMindfulGoalUser(json);
//   const mindfulTodoUser = Convert.toMindfulTodoUser(json);
//   const todosV2 = Convert.toTodosV2(json);
//   const deletedAt = Convert.toDeletedAt(json);
//   const todosV1 = Convert.toTodosV1(json);
//   const dailyTodoV1 = Convert.toDailyTodoV1(json);
//   const dayTasksV1 = Convert.toDayTasksV1(json);
//   const userInfoV1 = Convert.toUserInfoV1(json);
//   const accomplishmentV1 = Convert.toAccomplishmentV1(json);
//   const activityV1 = Convert.toActivityV1(json);
//   const androidBehaviorV1 = Convert.toAndroidBehaviorV1(json);
//   const questionV1 = Convert.toQuestionV1(json);
//   const answerV1 = Convert.toAnswerV1(json);
//   const desktopBehaviorV1 = Convert.toDesktopBehaviorV1(json);
//   const goalV1 = Convert.toGoalV1(json);
//   const habitV1 = Convert.toHabitV1(json);
//   const checkInV1 = Convert.toCheckInV1(json);
//   const reminderV1 = Convert.toReminderV1(json);
//   const deviceBehaviorV1 = Convert.toDeviceBehaviorV1(json);
//   const mediaV1 = Convert.toMediaV1(json);
//   const mindsetV1 = Convert.toMindsetV1(json);
//   const profileV1 = Convert.toProfileV1(json);
//   const stepV1 = Convert.toStepV1(json);
//   const subscriptionV1 = Convert.toSubscriptionV1(json);
//   const userActivationV1 = Convert.toUserActivationV1(json);
//   const updateUserRequestV1 = Convert.toUpdateUserRequestV1(json);
//   const initialUserDataV1 = Convert.toInitialUserDataV1(json);
//   const promoCodeV1 = Convert.toPromoCodeV1(json);
//   const customizationV1 = Convert.toCustomizationV1(json);
//   const deleteOrArchivePayload = Convert.toDeleteOrArchivePayload(json);
//   const unarchivePayload = Convert.toUnarchivePayload(json);
//   const updateOrderingPayload = Convert.toUpdateOrderingPayload(json);
//   const addProgressToGoalPayload = Convert.toAddProgressToGoalPayload(json);
//   const checkInPayload = Convert.toCheckInPayload(json);
//   const uncheckInPayload = Convert.toUncheckInPayload(json);
//   const checkInPayloadV2 = Convert.toCheckInPayloadV2(json);
//   const uncheckInPayloadV2 = Convert.toUncheckInPayloadV2(json);
//   const validatePurchasePayload = Convert.toValidatePurchasePayload(json);
//   const purchasedItem = Convert.toPurchasedItem(json);
//   const subscriptionVerificationInfo = Convert.toSubscriptionVerificationInfo(json);
//   const validatePurchasePayloadV2 = Convert.toValidatePurchasePayloadV2(json);
//   const applyPromoCodePayload = Convert.toApplyPromoCodePayload(json);
//   const applyPromoCodeResponse = Convert.toApplyPromoCodeResponse(json);
//   const verifyCodePayload = Convert.toVerifyCodePayload(json);
//   const noPurchaseFoundPayload = Convert.toNoPurchaseFoundPayload(json);
//   const todoAddTasksToDayPayload = Convert.toTodoAddTasksToDayPayload(json);
//   const todoUpdateTasksForDayPayload = Convert.toTodoUpdateTasksForDayPayload(json);
//   const todoMoveTasksToDayPayload = Convert.toTodoMoveTasksToDayPayload(json);
//   const todoAddTasksToBacklogPayload = Convert.toTodoAddTasksToBacklogPayload(json);
//   const todoMoveTasksToBacklogPayload = Convert.toTodoMoveTasksToBacklogPayload(json);
//   const todoDeleteTasksFromBacklogPayload = Convert.toTodoDeleteTasksFromBacklogPayload(json);
//   const todoUpdateBacklogOrderingPayload = Convert.toTodoUpdateBacklogOrderingPayload(json);
//   const todoSetActiveDayPayload = Convert.toTodoSetActiveDayPayload(json);
//   const updateTodosPayload = Convert.toUpdateTodosPayload(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.

export interface MindfulGoalUser {
    created_at?: number | null;
    id?:         null | string;
    updated_at?: number | null;
    user?:       null | UserInfoV1;
    version?:    UserInfoVersion | null;
}

export interface UserInfoV1 {
    accomplishment_ordering?: string[] | null;
    accomplishments?:         { [key: string]: AccomplishmentV1 } | null;
    activities?:              ActivityV1[] | null;
    answers?:                 AnswerV1[] | null;
    created_at?:              number | null;
    customizations?:          null | CustomizationV1;
    goal_ordering?:           string[] | null;
    goals?:                   { [key: string]: GoalV1 } | null;
    habit_ordering?:          string[] | null;
    habits?:                  { [key: string]: HabitV1 } | null;
    mindset_ordering?:        string[] | null;
    mindsets?:                { [key: string]: MindsetV1 } | null;
    profile?:                 null | ProfileV1;
    subscriptions?:           SubscriptionV1[] | null;
    updated_at?:              number | null;
}

export interface AccomplishmentV1 {
    archived?:   boolean | null;
    background?: null | MediaV1;
    content?:    null | string;
    created_at?: number | null;
    id?:         null | string;
    updated_at?: number | null;
}

export interface MediaV1 {
    deleted_at?: number | null;
    key?:        null | string;
    type?:       MediaType | null;
    url?:        null | string;
}

export enum MediaType {
    Photo = "photo",
    Video = "video",
}

export interface ActivityV1 {
    afterValue?:  null | string;
    beforeValue?: null | string;
    created_at?:  number | null;
    id?:          null | string;
    media?:       MediaV1[] | null;
    objectId?:    null | string;
    objectType?:  ObjectType | null;
    type?:        ActivityType | null;
    uid?:         null | string;
}

export enum ObjectType {
    AccomplishmentV1 = "accomplishment_v1",
    GoalV1 = "goal_v1",
    HabitV1 = "habit_v1",
    MindsetV1 = "mindset_v1",
    ProfileV1 = "profile_v1",
}

export enum ActivityType {
    AccomplishmentAdd = "accomplishment:add",
    AccomplishmentDelete = "accomplishment:delete",
    AccomplishmentUpdate = "accomplishment:update",
    GoalAdd = "goal:add",
    GoalArchive = "goal:archive",
    GoalDelete = "goal:delete",
    GoalProgress = "goal:progress",
    GoalReached = "goal:reached",
    GoalUnarchive = "goal:unarchive",
    GoalUpdate = "goal:update",
    HabitAdd = "habit:add",
    HabitArchive = "habit:archive",
    HabitCheckin = "habit:checkin",
    HabitComplete = "habit:complete",
    HabitDelete = "habit:delete",
    HabitUnarchive = "habit:unarchive",
    HabitUncheckin = "habit:uncheckin",
    HabitUpdate = "habit:update",
    MindsetAdd = "mindset:add",
    MindsetDelete = "mindset:delete",
    MindsetUpdate = "mindset:update",
    ProfileCreate = "profile:create",
}

export interface AnswerV1 {
    content?:    null | string;
    created_at?: number | null;
    id?:         null | string;
    question?:   null | QuestionV1;
    updated_at?: number | null;
}

export interface QuestionV1 {
    content?:    null | string;
    created_at?: number | null;
    id?:         null | string;
}

export interface CustomizationV1 {
    force_dark_mode?:   boolean | null;
    play_sound_effect?: boolean | null;
    show_all_habits?:   boolean | null;
    use_sunday_weeks?:  boolean | null;
}

export interface GoalV1 {
    archived?:         boolean | null;
    category?:         MainCategory | null;
    checkins?:         CheckInV1[] | null;
    color?:            ColorTheme | null;
    completed_steps?:  number | null;
    created_at?:       number | null;
    current_progress?: number | null;
    custom_category?:  null | string;
    custom_unit?:      null | string;
    deadline?:         number | null;
    id?:               null | string;
    inspiration?:      null | string;
    reminder?:         boolean | null;
    reward?:           null | string;
    steps?:            StepV1[] | null;
    subcategory?:      SecondaryCategory | null;
    subject?:          null | string;
    total_estimate?:   number | null;
    total_steps?:      number | null;
    unit?:             GoalUnit | null;
    updated_at?:       number | null;
    use_steps?:        boolean | null;
}

export enum MainCategory {
    Career = "career",
    Family = "family",
    Financial = "financial",
    Fun = "fun",
    Health = "health",
    Self = "self",
    Social = "social",
    Study = "study",
}

export interface CheckInV1 {
    created_at?: number | null;
    id?:         null | string;
    marked_at?:  number | null;
    media?:      null | MediaV1;
    notes?:      null | string;
}

export enum ColorTheme {
    Aqua = "aqua",
    Beige = "beige",
    Blue = "blue",
    Boyish = "boyish",
    Bright = "bright",
    Cafe = "cafe",
    Dream = "dream",
    Fluorescent = "fluorescent",
    Green = "green",
    Lake = "lake",
    Lavendar = "lavendar",
    Lawn = "lawn",
    Mars = "mars",
    Naked = "naked",
    Night = "night",
    Omelette = "omelette",
    Orange = "orange",
    Philosophy = "philosophy",
    Pink = "pink",
    Purple = "purple",
    Rain = "rain",
    Red = "red",
    Romance = "romance",
    Silk = "silk",
    Silver = "silver",
    Soft = "soft",
    Steel = "steel",
    Warmth = "warmth",
    Water = "water",
    Yellow = "yellow",
}

export interface StepV1 {
    complete?:     boolean | null;
    completed_at?: number | null;
    content?:      null | string;
    created_at?:   number | null;
    deadline?:     number | null;
    deleted_at?:   number | null;
    id?:           null | string;
    order?:        number | null;
    substeps?:     StepV1[] | null;
    updated_at?:   number | null;
}

export enum SecondaryCategory {
    Business = "business",
    Calm = "calm",
    Car = "car",
    Children = "children",
    Create = "create",
    Credit = "credit",
    Debt = "debt",
    Dental = "dental",
    Diet = "diet",
    Fitness = "fitness",
    Friends = "friends",
    Garden = "garden",
    Gratitude = "gratitude",
    Growth = "growth",
    Home = "home",
    Invest = "invest",
    Journal = "journal",
    Leadership = "leadership",
    Learning = "learning",
    Legacy = "legacy",
    Love = "love",
    Meditate = "meditate",
    Mentor = "mentor",
    Networking = "networking",
    Parent = "parent",
    Passive = "passive",
    Portfolio = "portfolio",
    Positive = "positive",
    Procrastinate = "procrastinate",
    Productive = "productive",
    Promotion = "promotion",
    Protein = "protein",
    Read = "read",
    Routine = "routine",
    Save = "save",
    Sleep = "sleep",
    Train = "train",
    Travel = "travel",
    Vacation = "vacation",
    Vegetable = "vegetable",
    Weight = "weight",
    Write = "write",
    Yoga = "yoga",
}

export enum GoalUnit {
    Book = "book",
    Chapter = "chapter",
    Class = "class",
    Course = "course",
    Day = "day",
    Euro = "euro",
    Gbp = "gbp",
    Hour = "hour",
    Item = "item",
    KM = "km",
    Kg = "kg",
    Kusd = "kusd",
    LB = "lb",
    Mile = "mile",
    Minute = "minute",
    Page = "page",
    Percent = "percent",
    Session = "session",
    Step = "step",
    Time = "time",
    Usd = "usd",
    Video = "video",
    Week = "week",
    Word = "word",
}

export interface HabitV1 {
    archived?:          boolean | null;
    capture_moment?:    boolean | null;
    category?:          MainCategory | null;
    checkins?:          CheckInV1[] | null;
    color?:             ColorTheme | null;
    created_at?:        number | null;
    custom_unit?:       null | string;
    frequency?:         number | null;
    id?:                null | string;
    inspiration?:       null | string;
    location?:          null | string;
    nested_rings?:      boolean | null;
    period?:            PeriodUnit | null;
    periods?:           number | null;
    reminder?:          boolean | null;
    reminder_time?:     number | null;
    reminder_weekdays?: number[] | null;
    subcategory?:       SecondaryCategory | null;
    subject?:           null | string;
    unit?:              HabitUnit | null;
    updated_at?:        number | null;
}

export enum PeriodUnit {
    Day = "day",
    Month = "month",
    Week = "week",
}

export enum HabitUnit {
    Time = "time",
}

export interface MindsetV1 {
    archived?:   boolean | null;
    background?: null | MediaV1;
    content?:    null | string;
    created_at?: number | null;
    id?:         null | string;
    theme?:      MindsetTheme | null;
    updated_at?: number | null;
}

export enum MindsetTheme {
    Default = "default",
}

export interface ProfileV1 {
    name?:    null | string;
    profile?: null | MediaV1;
}

export interface SubscriptionV1 {
    expires_at?:  number | null;
    method?:      SubscriptionMethod | null;
    product?:     MindfulInAppPurchase | null;
    purchase_id?: null | string;
    started_at?:  number | null;
}

export enum SubscriptionMethod {
    Apple = "apple",
    Google = "google",
    Grant = "grant",
    Gumroad = "gumroad",
    Promotion = "promotion",
}

export enum MindfulInAppPurchase {
    AnnualSubscription = "annual_subscription",
    LifeTimeAccess = "life_time_access",
    LifeTimeAccess2020 = "life_time_access_2020",
    MonthlySubscription = "monthly_subscription",
    MonthlySubscription2020_03 = "monthly_subscription_2020_03",
    QuarterlySubscription = "quarterly_subscription",
}

export enum UserInfoVersion {
    V1 = "v1",
}

export interface MindfulTodoUser {
    created_at?:    number | null;
    id?:            null | string;
    profile?:       null | ProfileV1;
    reminders?:     ReminderV1[] | null;
    subscriptions?: SubscriptionV1[] | null;
    todos?:         null | TodosV2;
    updated_at?:    number | null;
    version?:       null | string;
}

export interface ReminderV1 {
    time?:     number | null;
    weekdays?: number[] | null;
}

export interface TodosV2 {
    active?:           { [key: string]: DayTasksV1 } | null;
    backlog?:          string[] | null;
    created_at?:       number | null;
    recently_deleted?: { [key: string]: DeletedAt[] } | null;
    tasks?:            { [key: string]: StepV1 } | null;
    updated_at?:       number | null;
}

export interface DayTasksV1 {
    created_for?:    null | string;
    device_ids?:     string[] | null;
    ends_at?:        number | null;
    is_auto_review?: boolean | null;
    reviewed_at?:    number | null;
    starts_at?:      number | null;
    tasks?:          string[] | null;
}

export interface DeletedAt {
    deleted_at?: number | null;
    id?:         null | string;
}

export interface TodosV1 {
    active_todos?:     null | DailyTodoV1;
    backlog?:          { [key: string]: StepV1 } | null;
    backlog_ordering?: string[] | null;
    created_at?:       number | null;
    device_id?:        null | string;
    updated_at?:       number | null;
}

export interface DailyTodoV1 {
    created_for?: null | string;
    expires_at?:  number | null;
    tasks?:       StepV1[] | null;
    updated_at?:  number | null;
}

export interface AndroidBehaviorV1 {
    created_at?: number | null;
    updated_at?: number | null;
}

export interface DesktopBehaviorV1 {
    created_at?: number | null;
    updated_at?: number | null;
}

export interface UserActivationV1 {
    accomplishment_set?:           boolean | null;
    aha_moment_reached?:           boolean | null;
    android_behavior?:             null | DeviceBehaviorV1;
    channel?:                      null | string;
    chrome_behavior?:              null | DeviceBehaviorV1;
    chrome_extension_installed?:   boolean | null;
    desktop_app_installed?:        boolean | null;
    desktop_notification_enabled?: boolean | null;
    desktop_onboarding_finished?:  boolean | null;
    goal_set?:                     boolean | null;
    habit_moment_reached?:         boolean | null;
    habit_set?:                    boolean | null;
    ios_behavior?:                 null | DeviceBehaviorV1;
    ipad_behavior?:                null | DeviceBehaviorV1;
    iwatch_behavior?:              null | DeviceBehaviorV1;
    last_seen_at?:                 number | null;
    mac_behavior?:                 null | DeviceBehaviorV1;
    mobile_app_installed?:         boolean | null;
    mobile_notification_enabled?:  boolean | null;
    mobile_onboarding_finished?:   boolean | null;
    setup_moment_reached?:         boolean | null;
    web_notification_enabled?:     boolean | null;
    web_onboarding_finished?:      boolean | null;
    windows_behavior?:             null | DeviceBehaviorV1;
}

export interface DeviceBehaviorV1 {
    created_at?:           number | null;
    notification_enabled?: boolean | null;
}

export interface UpdateUserRequestV1 {
    accomplishments?: AccomplishmentV1[] | null;
    goals?:           GoalV1[] | null;
    habits?:          HabitV1[] | null;
    mindsets?:        MindsetV1[] | null;
    profile?:         null | ProfileV1;
}

export interface InitialUserDataV1 {
    accomplishment?:   string;
    accomplishmentId?: string;
    goal?:             string;
    goalId?:           string;
    habit?:            string;
    habitId?:          string;
    mindset?:          string;
    mindsetId?:        string;
}

export interface DeleteOrArchivePayload {
    archive?: boolean;
    id?:      string;
    type?:    ObjectType;
}

export interface UnarchivePayload {
    id?:   string;
    type?: ObjectType;
}

export interface UpdateOrderingPayload {
    newOrdering?: string[];
    type?:        ObjectType;
}

export interface AddProgressToGoalPayload {
    goalId?:           string;
    newProgress?:      number | null;
    newTotalProgress?: number | null;
    steps?:            StepV1[];
}

export interface CheckInPayload {
    disable_capture?: boolean | null;
    habitId?:         string;
    media?:           null | MediaV1;
    note?:            null | string;
    startOfDay?:      number;
}

export interface UncheckInPayload {
    habitId?:    string;
    startOfDay?: number;
}

export interface CheckInPayloadV2 {
    disable_capture?: boolean | null;
    endOfDay?:        number;
    habitId?:         string;
    media?:           null | MediaV1;
    note?:            null | string;
    startOfDay?:      number;
}

export interface UncheckInPayloadV2 {
    endOfDay?:   number;
    habitId?:    string;
    startOfDay?: number;
}

export interface SubscriptionVerificationInfo {
    payload?:     null | ValidatePurchasePayload;
    product?:     null | string;
    purchase?:    null | PurchasedItem;
    purchase_id?: null | string;
    valid?:       boolean | null;
}

export interface ValidatePurchasePayload {
    method?:          SubscriptionMethod;
    product_id?:      string;
    purchase_id?:     null | string;
    sandbox?:         boolean;
    validation_data?: string;
}

export interface PurchasedItem {
    appItemId?:             string;
    bundleId?:              string;
    cancellationDate?:      number | null | string;
    expirationDate?:        number | null | string;
    isTrial?:               boolean;
    orderId?:               string;
    originalPurchaseDate?:  string;
    originalTransactionId?: string;
    productId?:             string;
    purchaseDate?:          number | string;
    quantity?:              number;
    transactionId?:         string;
}

export interface ValidatePurchasePayloadV2 {
    purchases?: ValidatePurchasePayload[];
}

export interface ApplyPromoCodePayload {
    code?: string;
}

export interface ApplyPromoCodeResponse {
    promo_code?: null | PromoCodeV1;
    result?:     boolean;
}

export interface PromoCodeV1 {
    applied_at?:     number | null;
    applied_to?:     null | string;
    campaign?:       null | string;
    code?:           null | string;
    created_at?:     number;
    duration_in_ms?: number | null;
    product_id?:     null | string;
    promo_price?:    number | null;
    vendor?:         null | string;
}

export interface VerifyCodePayload {
    code?:  string;
    email?: string;
}

export interface NoPurchaseFoundPayload {
    method?: SubscriptionMethod;
}

export interface TodoAddTasksToDayPayload {
    command?:         TodoCommand;
    conceptual_date?: string;
    tasks_to_add?:    StepV1[];
}

export enum TodoCommand {
    AddTasksToBacklog = "add_tasks_to_backlog",
    AddTasksToDay = "add_tasks_to_day",
    DeleteTaskFromBacklog = "delete_task_from_backlog",
    MoveTasksToBacklog = "move_tasks_to_backlog",
    MoveTasksToDay = "move_tasks_to_day",
    SetActiveDay = "set_active_day",
    UpdateBacklogOrdering = "update_backlog_ordering",
    UpdateTasksForDay = "update_tasks_for_day",
}

export interface TodoUpdateTasksForDayPayload {
    command?:         TodoCommand;
    conceptual_date?: string;
    merge?:           boolean;
    tasks_to_update?: StepV1[];
}

export interface TodoMoveTasksToDayPayload {
    command?:         TodoCommand;
    conceptual_date?: string;
    tasks_to_move?:   StepV1[];
    todos?:           TodosV2;
}

export interface TodoAddTasksToBacklogPayload {
    command?:      TodoCommand;
    tasks_to_add?: StepV1[];
    todos?:        TodosV2;
}

export interface TodoMoveTasksToBacklogPayload {
    command?:         TodoCommand;
    conceptual_date?: string;
    tasks_to_move?:   StepV1[];
}

export interface TodoDeleteTasksFromBacklogPayload {
    command?:         TodoCommand;
    tasks_to_delete?: StepV1[];
}

export interface TodoUpdateBacklogOrderingPayload {
    backlog?:          { [key: string]: StepV1 };
    backlog_ordering?: string[];
    command?:          TodoCommand;
}

export interface TodoSetActiveDayPayload {
    command?:         TodoCommand;
    conceptual_date?: string;
}

export interface UpdateTodosPayload {
    active_day_is_today?: boolean | null;
    todos?:               TodosV2;
}

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class Convert {
    public static toActivityType(json: string): ActivityType {
        return cast(JSON.parse(json), r("ActivityType"));
    }

    public static activityTypeToJson(value: ActivityType): string {
        return JSON.stringify(uncast(value, r("ActivityType")), null, 2);
    }

    public static toObjectType(json: string): ObjectType {
        return cast(JSON.parse(json), r("ObjectType"));
    }

    public static objectTypeToJson(value: ObjectType): string {
        return JSON.stringify(uncast(value, r("ObjectType")), null, 2);
    }

    public static toMainCategory(json: string): MainCategory {
        return cast(JSON.parse(json), r("MainCategory"));
    }

    public static mainCategoryToJson(value: MainCategory): string {
        return JSON.stringify(uncast(value, r("MainCategory")), null, 2);
    }

    public static toSecondaryCategory(json: string): SecondaryCategory {
        return cast(JSON.parse(json), r("SecondaryCategory"));
    }

    public static secondaryCategoryToJson(value: SecondaryCategory): string {
        return JSON.stringify(uncast(value, r("SecondaryCategory")), null, 2);
    }

    public static toColorTheme(json: string): ColorTheme {
        return cast(JSON.parse(json), r("ColorTheme"));
    }

    public static colorThemeToJson(value: ColorTheme): string {
        return JSON.stringify(uncast(value, r("ColorTheme")), null, 2);
    }

    public static toGoalUnit(json: string): GoalUnit {
        return cast(JSON.parse(json), r("GoalUnit"));
    }

    public static goalUnitToJson(value: GoalUnit): string {
        return JSON.stringify(uncast(value, r("GoalUnit")), null, 2);
    }

    public static toHabitUnit(json: string): HabitUnit {
        return cast(JSON.parse(json), r("HabitUnit"));
    }

    public static habitUnitToJson(value: HabitUnit): string {
        return JSON.stringify(uncast(value, r("HabitUnit")), null, 2);
    }

    public static toPeriodUnit(json: string): PeriodUnit {
        return cast(JSON.parse(json), r("PeriodUnit"));
    }

    public static periodUnitToJson(value: PeriodUnit): string {
        return JSON.stringify(uncast(value, r("PeriodUnit")), null, 2);
    }

    public static toMindsetTheme(json: string): MindsetTheme {
        return cast(JSON.parse(json), r("MindsetTheme"));
    }

    public static mindsetThemeToJson(value: MindsetTheme): string {
        return JSON.stringify(uncast(value, r("MindsetTheme")), null, 2);
    }

    public static toSubscriptionMethod(json: string): SubscriptionMethod {
        return cast(JSON.parse(json), r("SubscriptionMethod"));
    }

    public static subscriptionMethodToJson(value: SubscriptionMethod): string {
        return JSON.stringify(uncast(value, r("SubscriptionMethod")), null, 2);
    }

    public static toUserInfoVersion(json: string): UserInfoVersion {
        return cast(JSON.parse(json), r("UserInfoVersion"));
    }

    public static userInfoVersionToJson(value: UserInfoVersion): string {
        return JSON.stringify(uncast(value, r("UserInfoVersion")), null, 2);
    }

    public static toMediaType(json: string): MediaType {
        return cast(JSON.parse(json), r("MediaType"));
    }

    public static mediaTypeToJson(value: MediaType): string {
        return JSON.stringify(uncast(value, r("MediaType")), null, 2);
    }

    public static toMindfulInAppPurchase(json: string): MindfulInAppPurchase {
        return cast(JSON.parse(json), r("MindfulInAppPurchase"));
    }

    public static mindfulInAppPurchaseToJson(value: MindfulInAppPurchase): string {
        return JSON.stringify(uncast(value, r("MindfulInAppPurchase")), null, 2);
    }

    public static toTodoCommand(json: string): TodoCommand {
        return cast(JSON.parse(json), r("TodoCommand"));
    }

    public static todoCommandToJson(value: TodoCommand): string {
        return JSON.stringify(uncast(value, r("TodoCommand")), null, 2);
    }

    public static toMindfulGoalUser(json: string): MindfulGoalUser {
        return cast(JSON.parse(json), r("MindfulGoalUser"));
    }

    public static mindfulGoalUserToJson(value: MindfulGoalUser): string {
        return JSON.stringify(uncast(value, r("MindfulGoalUser")), null, 2);
    }

    public static toMindfulTodoUser(json: string): MindfulTodoUser {
        return cast(JSON.parse(json), r("MindfulTodoUser"));
    }

    public static mindfulTodoUserToJson(value: MindfulTodoUser): string {
        return JSON.stringify(uncast(value, r("MindfulTodoUser")), null, 2);
    }

    public static toTodosV2(json: string): TodosV2 {
        return cast(JSON.parse(json), r("TodosV2"));
    }

    public static todosV2ToJson(value: TodosV2): string {
        return JSON.stringify(uncast(value, r("TodosV2")), null, 2);
    }

    public static toDeletedAt(json: string): DeletedAt {
        return cast(JSON.parse(json), r("DeletedAt"));
    }

    public static deletedAtToJson(value: DeletedAt): string {
        return JSON.stringify(uncast(value, r("DeletedAt")), null, 2);
    }

    public static toTodosV1(json: string): TodosV1 {
        return cast(JSON.parse(json), r("TodosV1"));
    }

    public static todosV1ToJson(value: TodosV1): string {
        return JSON.stringify(uncast(value, r("TodosV1")), null, 2);
    }

    public static toDailyTodoV1(json: string): DailyTodoV1 {
        return cast(JSON.parse(json), r("DailyTodoV1"));
    }

    public static dailyTodoV1ToJson(value: DailyTodoV1): string {
        return JSON.stringify(uncast(value, r("DailyTodoV1")), null, 2);
    }

    public static toDayTasksV1(json: string): DayTasksV1 {
        return cast(JSON.parse(json), r("DayTasksV1"));
    }

    public static dayTasksV1ToJson(value: DayTasksV1): string {
        return JSON.stringify(uncast(value, r("DayTasksV1")), null, 2);
    }

    public static toUserInfoV1(json: string): UserInfoV1 {
        return cast(JSON.parse(json), r("UserInfoV1"));
    }

    public static userInfoV1ToJson(value: UserInfoV1): string {
        return JSON.stringify(uncast(value, r("UserInfoV1")), null, 2);
    }

    public static toAccomplishmentV1(json: string): AccomplishmentV1 {
        return cast(JSON.parse(json), r("AccomplishmentV1"));
    }

    public static accomplishmentV1ToJson(value: AccomplishmentV1): string {
        return JSON.stringify(uncast(value, r("AccomplishmentV1")), null, 2);
    }

    public static toActivityV1(json: string): ActivityV1 {
        return cast(JSON.parse(json), r("ActivityV1"));
    }

    public static activityV1ToJson(value: ActivityV1): string {
        return JSON.stringify(uncast(value, r("ActivityV1")), null, 2);
    }

    public static toAndroidBehaviorV1(json: string): AndroidBehaviorV1 {
        return cast(JSON.parse(json), r("AndroidBehaviorV1"));
    }

    public static androidBehaviorV1ToJson(value: AndroidBehaviorV1): string {
        return JSON.stringify(uncast(value, r("AndroidBehaviorV1")), null, 2);
    }

    public static toQuestionV1(json: string): QuestionV1 {
        return cast(JSON.parse(json), r("QuestionV1"));
    }

    public static questionV1ToJson(value: QuestionV1): string {
        return JSON.stringify(uncast(value, r("QuestionV1")), null, 2);
    }

    public static toAnswerV1(json: string): AnswerV1 {
        return cast(JSON.parse(json), r("AnswerV1"));
    }

    public static answerV1ToJson(value: AnswerV1): string {
        return JSON.stringify(uncast(value, r("AnswerV1")), null, 2);
    }

    public static toDesktopBehaviorV1(json: string): DesktopBehaviorV1 {
        return cast(JSON.parse(json), r("DesktopBehaviorV1"));
    }

    public static desktopBehaviorV1ToJson(value: DesktopBehaviorV1): string {
        return JSON.stringify(uncast(value, r("DesktopBehaviorV1")), null, 2);
    }

    public static toGoalV1(json: string): GoalV1 {
        return cast(JSON.parse(json), r("GoalV1"));
    }

    public static goalV1ToJson(value: GoalV1): string {
        return JSON.stringify(uncast(value, r("GoalV1")), null, 2);
    }

    public static toHabitV1(json: string): HabitV1 {
        return cast(JSON.parse(json), r("HabitV1"));
    }

    public static habitV1ToJson(value: HabitV1): string {
        return JSON.stringify(uncast(value, r("HabitV1")), null, 2);
    }

    public static toCheckInV1(json: string): CheckInV1 {
        return cast(JSON.parse(json), r("CheckInV1"));
    }

    public static checkInV1ToJson(value: CheckInV1): string {
        return JSON.stringify(uncast(value, r("CheckInV1")), null, 2);
    }

    public static toReminderV1(json: string): ReminderV1 {
        return cast(JSON.parse(json), r("ReminderV1"));
    }

    public static reminderV1ToJson(value: ReminderV1): string {
        return JSON.stringify(uncast(value, r("ReminderV1")), null, 2);
    }

    public static toDeviceBehaviorV1(json: string): DeviceBehaviorV1 {
        return cast(JSON.parse(json), r("DeviceBehaviorV1"));
    }

    public static deviceBehaviorV1ToJson(value: DeviceBehaviorV1): string {
        return JSON.stringify(uncast(value, r("DeviceBehaviorV1")), null, 2);
    }

    public static toMediaV1(json: string): MediaV1 {
        return cast(JSON.parse(json), r("MediaV1"));
    }

    public static mediaV1ToJson(value: MediaV1): string {
        return JSON.stringify(uncast(value, r("MediaV1")), null, 2);
    }

    public static toMindsetV1(json: string): MindsetV1 {
        return cast(JSON.parse(json), r("MindsetV1"));
    }

    public static mindsetV1ToJson(value: MindsetV1): string {
        return JSON.stringify(uncast(value, r("MindsetV1")), null, 2);
    }

    public static toProfileV1(json: string): ProfileV1 {
        return cast(JSON.parse(json), r("ProfileV1"));
    }

    public static profileV1ToJson(value: ProfileV1): string {
        return JSON.stringify(uncast(value, r("ProfileV1")), null, 2);
    }

    public static toStepV1(json: string): StepV1 {
        return cast(JSON.parse(json), r("StepV1"));
    }

    public static stepV1ToJson(value: StepV1): string {
        return JSON.stringify(uncast(value, r("StepV1")), null, 2);
    }

    public static toSubscriptionV1(json: string): SubscriptionV1 {
        return cast(JSON.parse(json), r("SubscriptionV1"));
    }

    public static subscriptionV1ToJson(value: SubscriptionV1): string {
        return JSON.stringify(uncast(value, r("SubscriptionV1")), null, 2);
    }

    public static toUserActivationV1(json: string): UserActivationV1 {
        return cast(JSON.parse(json), r("UserActivationV1"));
    }

    public static userActivationV1ToJson(value: UserActivationV1): string {
        return JSON.stringify(uncast(value, r("UserActivationV1")), null, 2);
    }

    public static toUpdateUserRequestV1(json: string): UpdateUserRequestV1 {
        return cast(JSON.parse(json), r("UpdateUserRequestV1"));
    }

    public static updateUserRequestV1ToJson(value: UpdateUserRequestV1): string {
        return JSON.stringify(uncast(value, r("UpdateUserRequestV1")), null, 2);
    }

    public static toInitialUserDataV1(json: string): InitialUserDataV1 {
        return cast(JSON.parse(json), r("InitialUserDataV1"));
    }

    public static initialUserDataV1ToJson(value: InitialUserDataV1): string {
        return JSON.stringify(uncast(value, r("InitialUserDataV1")), null, 2);
    }

    public static toPromoCodeV1(json: string): PromoCodeV1 {
        return cast(JSON.parse(json), r("PromoCodeV1"));
    }

    public static promoCodeV1ToJson(value: PromoCodeV1): string {
        return JSON.stringify(uncast(value, r("PromoCodeV1")), null, 2);
    }

    public static toCustomizationV1(json: string): CustomizationV1 {
        return cast(JSON.parse(json), r("CustomizationV1"));
    }

    public static customizationV1ToJson(value: CustomizationV1): string {
        return JSON.stringify(uncast(value, r("CustomizationV1")), null, 2);
    }

    public static toDeleteOrArchivePayload(json: string): DeleteOrArchivePayload {
        return cast(JSON.parse(json), r("DeleteOrArchivePayload"));
    }

    public static deleteOrArchivePayloadToJson(value: DeleteOrArchivePayload): string {
        return JSON.stringify(uncast(value, r("DeleteOrArchivePayload")), null, 2);
    }

    public static toUnarchivePayload(json: string): UnarchivePayload {
        return cast(JSON.parse(json), r("UnarchivePayload"));
    }

    public static unarchivePayloadToJson(value: UnarchivePayload): string {
        return JSON.stringify(uncast(value, r("UnarchivePayload")), null, 2);
    }

    public static toUpdateOrderingPayload(json: string): UpdateOrderingPayload {
        return cast(JSON.parse(json), r("UpdateOrderingPayload"));
    }

    public static updateOrderingPayloadToJson(value: UpdateOrderingPayload): string {
        return JSON.stringify(uncast(value, r("UpdateOrderingPayload")), null, 2);
    }

    public static toAddProgressToGoalPayload(json: string): AddProgressToGoalPayload {
        return cast(JSON.parse(json), r("AddProgressToGoalPayload"));
    }

    public static addProgressToGoalPayloadToJson(value: AddProgressToGoalPayload): string {
        return JSON.stringify(uncast(value, r("AddProgressToGoalPayload")), null, 2);
    }

    public static toCheckInPayload(json: string): CheckInPayload {
        return cast(JSON.parse(json), r("CheckInPayload"));
    }

    public static checkInPayloadToJson(value: CheckInPayload): string {
        return JSON.stringify(uncast(value, r("CheckInPayload")), null, 2);
    }

    public static toUncheckInPayload(json: string): UncheckInPayload {
        return cast(JSON.parse(json), r("UncheckInPayload"));
    }

    public static uncheckInPayloadToJson(value: UncheckInPayload): string {
        return JSON.stringify(uncast(value, r("UncheckInPayload")), null, 2);
    }

    public static toCheckInPayloadV2(json: string): CheckInPayloadV2 {
        return cast(JSON.parse(json), r("CheckInPayloadV2"));
    }

    public static checkInPayloadV2ToJson(value: CheckInPayloadV2): string {
        return JSON.stringify(uncast(value, r("CheckInPayloadV2")), null, 2);
    }

    public static toUncheckInPayloadV2(json: string): UncheckInPayloadV2 {
        return cast(JSON.parse(json), r("UncheckInPayloadV2"));
    }

    public static uncheckInPayloadV2ToJson(value: UncheckInPayloadV2): string {
        return JSON.stringify(uncast(value, r("UncheckInPayloadV2")), null, 2);
    }

    public static toValidatePurchasePayload(json: string): ValidatePurchasePayload {
        return cast(JSON.parse(json), r("ValidatePurchasePayload"));
    }

    public static validatePurchasePayloadToJson(value: ValidatePurchasePayload): string {
        return JSON.stringify(uncast(value, r("ValidatePurchasePayload")), null, 2);
    }

    public static toPurchasedItem(json: string): PurchasedItem {
        return cast(JSON.parse(json), r("PurchasedItem"));
    }

    public static purchasedItemToJson(value: PurchasedItem): string {
        return JSON.stringify(uncast(value, r("PurchasedItem")), null, 2);
    }

    public static toSubscriptionVerificationInfo(json: string): SubscriptionVerificationInfo {
        return cast(JSON.parse(json), r("SubscriptionVerificationInfo"));
    }

    public static subscriptionVerificationInfoToJson(value: SubscriptionVerificationInfo): string {
        return JSON.stringify(uncast(value, r("SubscriptionVerificationInfo")), null, 2);
    }

    public static toValidatePurchasePayloadV2(json: string): ValidatePurchasePayloadV2 {
        return cast(JSON.parse(json), r("ValidatePurchasePayloadV2"));
    }

    public static validatePurchasePayloadV2ToJson(value: ValidatePurchasePayloadV2): string {
        return JSON.stringify(uncast(value, r("ValidatePurchasePayloadV2")), null, 2);
    }

    public static toApplyPromoCodePayload(json: string): ApplyPromoCodePayload {
        return cast(JSON.parse(json), r("ApplyPromoCodePayload"));
    }

    public static applyPromoCodePayloadToJson(value: ApplyPromoCodePayload): string {
        return JSON.stringify(uncast(value, r("ApplyPromoCodePayload")), null, 2);
    }

    public static toApplyPromoCodeResponse(json: string): ApplyPromoCodeResponse {
        return cast(JSON.parse(json), r("ApplyPromoCodeResponse"));
    }

    public static applyPromoCodeResponseToJson(value: ApplyPromoCodeResponse): string {
        return JSON.stringify(uncast(value, r("ApplyPromoCodeResponse")), null, 2);
    }

    public static toVerifyCodePayload(json: string): VerifyCodePayload {
        return cast(JSON.parse(json), r("VerifyCodePayload"));
    }

    public static verifyCodePayloadToJson(value: VerifyCodePayload): string {
        return JSON.stringify(uncast(value, r("VerifyCodePayload")), null, 2);
    }

    public static toNoPurchaseFoundPayload(json: string): NoPurchaseFoundPayload {
        return cast(JSON.parse(json), r("NoPurchaseFoundPayload"));
    }

    public static noPurchaseFoundPayloadToJson(value: NoPurchaseFoundPayload): string {
        return JSON.stringify(uncast(value, r("NoPurchaseFoundPayload")), null, 2);
    }

    public static toTodoAddTasksToDayPayload(json: string): TodoAddTasksToDayPayload {
        return cast(JSON.parse(json), r("TodoAddTasksToDayPayload"));
    }

    public static todoAddTasksToDayPayloadToJson(value: TodoAddTasksToDayPayload): string {
        return JSON.stringify(uncast(value, r("TodoAddTasksToDayPayload")), null, 2);
    }

    public static toTodoUpdateTasksForDayPayload(json: string): TodoUpdateTasksForDayPayload {
        return cast(JSON.parse(json), r("TodoUpdateTasksForDayPayload"));
    }

    public static todoUpdateTasksForDayPayloadToJson(value: TodoUpdateTasksForDayPayload): string {
        return JSON.stringify(uncast(value, r("TodoUpdateTasksForDayPayload")), null, 2);
    }

    public static toTodoMoveTasksToDayPayload(json: string): TodoMoveTasksToDayPayload {
        return cast(JSON.parse(json), r("TodoMoveTasksToDayPayload"));
    }

    public static todoMoveTasksToDayPayloadToJson(value: TodoMoveTasksToDayPayload): string {
        return JSON.stringify(uncast(value, r("TodoMoveTasksToDayPayload")), null, 2);
    }

    public static toTodoAddTasksToBacklogPayload(json: string): TodoAddTasksToBacklogPayload {
        return cast(JSON.parse(json), r("TodoAddTasksToBacklogPayload"));
    }

    public static todoAddTasksToBacklogPayloadToJson(value: TodoAddTasksToBacklogPayload): string {
        return JSON.stringify(uncast(value, r("TodoAddTasksToBacklogPayload")), null, 2);
    }

    public static toTodoMoveTasksToBacklogPayload(json: string): TodoMoveTasksToBacklogPayload {
        return cast(JSON.parse(json), r("TodoMoveTasksToBacklogPayload"));
    }

    public static todoMoveTasksToBacklogPayloadToJson(value: TodoMoveTasksToBacklogPayload): string {
        return JSON.stringify(uncast(value, r("TodoMoveTasksToBacklogPayload")), null, 2);
    }

    public static toTodoDeleteTasksFromBacklogPayload(json: string): TodoDeleteTasksFromBacklogPayload {
        return cast(JSON.parse(json), r("TodoDeleteTasksFromBacklogPayload"));
    }

    public static todoDeleteTasksFromBacklogPayloadToJson(value: TodoDeleteTasksFromBacklogPayload): string {
        return JSON.stringify(uncast(value, r("TodoDeleteTasksFromBacklogPayload")), null, 2);
    }

    public static toTodoUpdateBacklogOrderingPayload(json: string): TodoUpdateBacklogOrderingPayload {
        return cast(JSON.parse(json), r("TodoUpdateBacklogOrderingPayload"));
    }

    public static todoUpdateBacklogOrderingPayloadToJson(value: TodoUpdateBacklogOrderingPayload): string {
        return JSON.stringify(uncast(value, r("TodoUpdateBacklogOrderingPayload")), null, 2);
    }

    public static toTodoSetActiveDayPayload(json: string): TodoSetActiveDayPayload {
        return cast(JSON.parse(json), r("TodoSetActiveDayPayload"));
    }

    public static todoSetActiveDayPayloadToJson(value: TodoSetActiveDayPayload): string {
        return JSON.stringify(uncast(value, r("TodoSetActiveDayPayload")), null, 2);
    }

    public static toUpdateTodosPayload(json: string): UpdateTodosPayload {
        return cast(JSON.parse(json), r("UpdateTodosPayload"));
    }

    public static updateTodosPayloadToJson(value: UpdateTodosPayload): string {
        return JSON.stringify(uncast(value, r("UpdateTodosPayload")), null, 2);
    }
}

function invalidValue(typ: any, val: any): never {
    throw Error(`Invalid value ${JSON.stringify(val)} for type ${JSON.stringify(typ)}`);
}

function jsonToJSProps(typ: any): any {
    if (typ.jsonToJS === undefined) {
        var map: any = {};
        typ.props.forEach((p: any) => map[p.json] = { key: p.js, typ: p.typ });
        typ.jsonToJS = map;
    }
    return typ.jsonToJS;
}

function jsToJSONProps(typ: any): any {
    if (typ.jsToJSON === undefined) {
        var map: any = {};
        typ.props.forEach((p: any) => map[p.js] = { key: p.json, typ: p.typ });
        typ.jsToJSON = map;
    }
    return typ.jsToJSON;
}

function transform(val: any, typ: any, getProps: any): any {
    function transformPrimitive(typ: string, val: any): any {
        if (typeof typ === typeof val) return val;
        return invalidValue(typ, val);
    }

    function transformUnion(typs: any[], val: any): any {
        // val must validate against one typ in typs
        var l = typs.length;
        for (var i = 0; i < l; i++) {
            var typ = typs[i];
            try {
                return transform(val, typ, getProps);
            } catch (_) {}
        }
        return invalidValue(typs, val);
    }

    function transformEnum(cases: string[], val: any): any {
        if (cases.indexOf(val) !== -1) return val;
        return invalidValue(cases, val);
    }

    function transformArray(typ: any, val: any): any {
        // val must be an array with no invalid elements
        if (!Array.isArray(val)) return invalidValue("array", val);
        return val.map(el => transform(el, typ, getProps));
    }

    function transformDate(typ: any, val: any): any {
        if (val === null) {
            return null;
        }
        const d = new Date(val);
        if (isNaN(d.valueOf())) {
            return invalidValue("Date", val);
        }
        return d;
    }

    function transformObject(props: { [k: string]: any }, additional: any, val: any): any {
        if (val === null || typeof val !== "object" || Array.isArray(val)) {
            return invalidValue("object", val);
        }
        var result: any = {};
        Object.getOwnPropertyNames(props).forEach(key => {
            const prop = props[key];
            const v = Object.prototype.hasOwnProperty.call(val, key) ? val[key] : undefined;
            result[prop.key] = transform(v, prop.typ, getProps);
        });
        Object.getOwnPropertyNames(val).forEach(key => {
            if (!Object.prototype.hasOwnProperty.call(props, key)) {
                result[key] = transform(val[key], additional, getProps);
            }
        });
        return result;
    }

    if (typ === "any") return val;
    if (typ === null) {
        if (val === null) return val;
        return invalidValue(typ, val);
    }
    if (typ === false) return invalidValue(typ, val);
    while (typeof typ === "object" && typ.ref !== undefined) {
        typ = typeMap[typ.ref];
    }
    if (Array.isArray(typ)) return transformEnum(typ, val);
    if (typeof typ === "object") {
        return typ.hasOwnProperty("unionMembers") ? transformUnion(typ.unionMembers, val)
            : typ.hasOwnProperty("arrayItems")    ? transformArray(typ.arrayItems, val)
            : typ.hasOwnProperty("props")         ? transformObject(getProps(typ), typ.additional, val)
            : invalidValue(typ, val);
    }
    // Numbers can be parsed by Date but shouldn't be.
    if (typ === Date && typeof val !== "number") return transformDate(typ, val);
    return transformPrimitive(typ, val);
}

function cast<T>(val: any, typ: any): T {
    return transform(val, typ, jsonToJSProps);
}

function uncast<T>(val: T, typ: any): any {
    return transform(val, typ, jsToJSONProps);
}

function a(typ: any) {
    return { arrayItems: typ };
}

function u(...typs: any[]) {
    return { unionMembers: typs };
}

function o(props: any[], additional: any) {
    return { props, additional };
}

function m(additional: any) {
    return { props: [], additional };
}

function r(name: string) {
    return { ref: name };
}

const typeMap: any = {
    "MindfulGoalUser": o([
        { json: "created_at", js: "created_at", typ: u(undefined, u(3.14, null)) },
        { json: "id", js: "id", typ: u(undefined, u(null, "")) },
        { json: "updated_at", js: "updated_at", typ: u(undefined, u(3.14, null)) },
        { json: "user", js: "user", typ: u(undefined, u(null, r("UserInfoV1"))) },
        { json: "version", js: "version", typ: u(undefined, u(r("UserInfoVersion"), null)) },
    ], "any"),
    "UserInfoV1": o([
        { json: "accomplishment_ordering", js: "accomplishment_ordering", typ: u(undefined, u(a(""), null)) },
        { json: "accomplishments", js: "accomplishments", typ: u(undefined, u(m(r("AccomplishmentV1")), null)) },
        { json: "activities", js: "activities", typ: u(undefined, u(a(r("ActivityV1")), null)) },
        { json: "answers", js: "answers", typ: u(undefined, u(a(r("AnswerV1")), null)) },
        { json: "created_at", js: "created_at", typ: u(undefined, u(3.14, null)) },
        { json: "customizations", js: "customizations", typ: u(undefined, u(null, r("CustomizationV1"))) },
        { json: "goal_ordering", js: "goal_ordering", typ: u(undefined, u(a(""), null)) },
        { json: "goals", js: "goals", typ: u(undefined, u(m(r("GoalV1")), null)) },
        { json: "habit_ordering", js: "habit_ordering", typ: u(undefined, u(a(""), null)) },
        { json: "habits", js: "habits", typ: u(undefined, u(m(r("HabitV1")), null)) },
        { json: "mindset_ordering", js: "mindset_ordering", typ: u(undefined, u(a(""), null)) },
        { json: "mindsets", js: "mindsets", typ: u(undefined, u(m(r("MindsetV1")), null)) },
        { json: "profile", js: "profile", typ: u(undefined, u(null, r("ProfileV1"))) },
        { json: "subscriptions", js: "subscriptions", typ: u(undefined, u(a(r("SubscriptionV1")), null)) },
        { json: "updated_at", js: "updated_at", typ: u(undefined, u(3.14, null)) },
    ], "any"),
    "AccomplishmentV1": o([
        { json: "archived", js: "archived", typ: u(undefined, u(true, null)) },
        { json: "background", js: "background", typ: u(undefined, u(null, r("MediaV1"))) },
        { json: "content", js: "content", typ: u(undefined, u(null, "")) },
        { json: "created_at", js: "created_at", typ: u(undefined, u(3.14, null)) },
        { json: "id", js: "id", typ: u(undefined, u(null, "")) },
        { json: "updated_at", js: "updated_at", typ: u(undefined, u(3.14, null)) },
    ], "any"),
    "MediaV1": o([
        { json: "deleted_at", js: "deleted_at", typ: u(undefined, u(3.14, null)) },
        { json: "key", js: "key", typ: u(undefined, u(null, "")) },
        { json: "type", js: "type", typ: u(undefined, u(r("MediaType"), null)) },
        { json: "url", js: "url", typ: u(undefined, u(null, "")) },
    ], "any"),
    "ActivityV1": o([
        { json: "afterValue", js: "afterValue", typ: u(undefined, u(null, "")) },
        { json: "beforeValue", js: "beforeValue", typ: u(undefined, u(null, "")) },
        { json: "created_at", js: "created_at", typ: u(undefined, u(3.14, null)) },
        { json: "id", js: "id", typ: u(undefined, u(null, "")) },
        { json: "media", js: "media", typ: u(undefined, u(a(r("MediaV1")), null)) },
        { json: "objectId", js: "objectId", typ: u(undefined, u(null, "")) },
        { json: "objectType", js: "objectType", typ: u(undefined, u(r("ObjectType"), null)) },
        { json: "type", js: "type", typ: u(undefined, u(r("ActivityType"), null)) },
        { json: "uid", js: "uid", typ: u(undefined, u(null, "")) },
    ], "any"),
    "AnswerV1": o([
        { json: "content", js: "content", typ: u(undefined, u(null, "")) },
        { json: "created_at", js: "created_at", typ: u(undefined, u(3.14, null)) },
        { json: "id", js: "id", typ: u(undefined, u(null, "")) },
        { json: "question", js: "question", typ: u(undefined, u(null, r("QuestionV1"))) },
        { json: "updated_at", js: "updated_at", typ: u(undefined, u(3.14, null)) },
    ], "any"),
    "QuestionV1": o([
        { json: "content", js: "content", typ: u(undefined, u(null, "")) },
        { json: "created_at", js: "created_at", typ: u(undefined, u(3.14, null)) },
        { json: "id", js: "id", typ: u(undefined, u(null, "")) },
    ], "any"),
    "CustomizationV1": o([
        { json: "force_dark_mode", js: "force_dark_mode", typ: u(undefined, u(true, null)) },
        { json: "play_sound_effect", js: "play_sound_effect", typ: u(undefined, u(true, null)) },
        { json: "show_all_habits", js: "show_all_habits", typ: u(undefined, u(true, null)) },
        { json: "use_sunday_weeks", js: "use_sunday_weeks", typ: u(undefined, u(true, null)) },
    ], "any"),
    "GoalV1": o([
        { json: "archived", js: "archived", typ: u(undefined, u(true, null)) },
        { json: "category", js: "category", typ: u(undefined, u(r("MainCategory"), null)) },
        { json: "checkins", js: "checkins", typ: u(undefined, u(a(r("CheckInV1")), null)) },
        { json: "color", js: "color", typ: u(undefined, u(r("ColorTheme"), null)) },
        { json: "completed_steps", js: "completed_steps", typ: u(undefined, u(3.14, null)) },
        { json: "created_at", js: "created_at", typ: u(undefined, u(3.14, null)) },
        { json: "current_progress", js: "current_progress", typ: u(undefined, u(3.14, null)) },
        { json: "custom_category", js: "custom_category", typ: u(undefined, u(null, "")) },
        { json: "custom_unit", js: "custom_unit", typ: u(undefined, u(null, "")) },
        { json: "deadline", js: "deadline", typ: u(undefined, u(3.14, null)) },
        { json: "id", js: "id", typ: u(undefined, u(null, "")) },
        { json: "inspiration", js: "inspiration", typ: u(undefined, u(null, "")) },
        { json: "reminder", js: "reminder", typ: u(undefined, u(true, null)) },
        { json: "reward", js: "reward", typ: u(undefined, u(null, "")) },
        { json: "steps", js: "steps", typ: u(undefined, u(a(r("StepV1")), null)) },
        { json: "subcategory", js: "subcategory", typ: u(undefined, u(r("SecondaryCategory"), null)) },
        { json: "subject", js: "subject", typ: u(undefined, u(null, "")) },
        { json: "total_estimate", js: "total_estimate", typ: u(undefined, u(3.14, null)) },
        { json: "total_steps", js: "total_steps", typ: u(undefined, u(3.14, null)) },
        { json: "unit", js: "unit", typ: u(undefined, u(r("GoalUnit"), null)) },
        { json: "updated_at", js: "updated_at", typ: u(undefined, u(3.14, null)) },
        { json: "use_steps", js: "use_steps", typ: u(undefined, u(true, null)) },
    ], "any"),
    "CheckInV1": o([
        { json: "created_at", js: "created_at", typ: u(undefined, u(3.14, null)) },
        { json: "id", js: "id", typ: u(undefined, u(null, "")) },
        { json: "marked_at", js: "marked_at", typ: u(undefined, u(3.14, null)) },
        { json: "media", js: "media", typ: u(undefined, u(null, r("MediaV1"))) },
        { json: "notes", js: "notes", typ: u(undefined, u(null, "")) },
    ], "any"),
    "StepV1": o([
        { json: "complete", js: "complete", typ: u(undefined, u(true, null)) },
        { json: "completed_at", js: "completed_at", typ: u(undefined, u(3.14, null)) },
        { json: "content", js: "content", typ: u(undefined, u(null, "")) },
        { json: "created_at", js: "created_at", typ: u(undefined, u(3.14, null)) },
        { json: "deadline", js: "deadline", typ: u(undefined, u(3.14, null)) },
        { json: "deleted_at", js: "deleted_at", typ: u(undefined, u(3.14, null)) },
        { json: "id", js: "id", typ: u(undefined, u(null, "")) },
        { json: "order", js: "order", typ: u(undefined, u(3.14, null)) },
        { json: "substeps", js: "substeps", typ: u(undefined, u(a(r("StepV1")), null)) },
        { json: "updated_at", js: "updated_at", typ: u(undefined, u(3.14, null)) },
    ], "any"),
    "HabitV1": o([
        { json: "archived", js: "archived", typ: u(undefined, u(true, null)) },
        { json: "capture_moment", js: "capture_moment", typ: u(undefined, u(true, null)) },
        { json: "category", js: "category", typ: u(undefined, u(r("MainCategory"), null)) },
        { json: "checkins", js: "checkins", typ: u(undefined, u(a(r("CheckInV1")), null)) },
        { json: "color", js: "color", typ: u(undefined, u(r("ColorTheme"), null)) },
        { json: "created_at", js: "created_at", typ: u(undefined, u(3.14, null)) },
        { json: "custom_unit", js: "custom_unit", typ: u(undefined, u(null, "")) },
        { json: "frequency", js: "frequency", typ: u(undefined, u(3.14, null)) },
        { json: "id", js: "id", typ: u(undefined, u(null, "")) },
        { json: "inspiration", js: "inspiration", typ: u(undefined, u(null, "")) },
        { json: "location", js: "location", typ: u(undefined, u(null, "")) },
        { json: "nested_rings", js: "nested_rings", typ: u(undefined, u(true, null)) },
        { json: "period", js: "period", typ: u(undefined, u(r("PeriodUnit"), null)) },
        { json: "periods", js: "periods", typ: u(undefined, u(3.14, null)) },
        { json: "reminder", js: "reminder", typ: u(undefined, u(true, null)) },
        { json: "reminder_time", js: "reminder_time", typ: u(undefined, u(3.14, null)) },
        { json: "reminder_weekdays", js: "reminder_weekdays", typ: u(undefined, u(a(3.14), null)) },
        { json: "subcategory", js: "subcategory", typ: u(undefined, u(r("SecondaryCategory"), null)) },
        { json: "subject", js: "subject", typ: u(undefined, u(null, "")) },
        { json: "unit", js: "unit", typ: u(undefined, u(r("HabitUnit"), null)) },
        { json: "updated_at", js: "updated_at", typ: u(undefined, u(3.14, null)) },
    ], "any"),
    "MindsetV1": o([
        { json: "archived", js: "archived", typ: u(undefined, u(true, null)) },
        { json: "background", js: "background", typ: u(undefined, u(null, r("MediaV1"))) },
        { json: "content", js: "content", typ: u(undefined, u(null, "")) },
        { json: "created_at", js: "created_at", typ: u(undefined, u(3.14, null)) },
        { json: "id", js: "id", typ: u(undefined, u(null, "")) },
        { json: "theme", js: "theme", typ: u(undefined, u(r("MindsetTheme"), null)) },
        { json: "updated_at", js: "updated_at", typ: u(undefined, u(3.14, null)) },
    ], "any"),
    "ProfileV1": o([
        { json: "name", js: "name", typ: u(undefined, u(null, "")) },
        { json: "profile", js: "profile", typ: u(undefined, u(null, r("MediaV1"))) },
    ], "any"),
    "SubscriptionV1": o([
        { json: "expires_at", js: "expires_at", typ: u(undefined, u(3.14, null)) },
        { json: "method", js: "method", typ: u(undefined, u(r("SubscriptionMethod"), null)) },
        { json: "product", js: "product", typ: u(undefined, u(r("MindfulInAppPurchase"), null)) },
        { json: "purchase_id", js: "purchase_id", typ: u(undefined, u(null, "")) },
        { json: "started_at", js: "started_at", typ: u(undefined, u(3.14, null)) },
    ], "any"),
    "MindfulTodoUser": o([
        { json: "created_at", js: "created_at", typ: u(undefined, u(3.14, null)) },
        { json: "id", js: "id", typ: u(undefined, u(null, "")) },
        { json: "profile", js: "profile", typ: u(undefined, u(null, r("ProfileV1"))) },
        { json: "reminders", js: "reminders", typ: u(undefined, u(a(r("ReminderV1")), null)) },
        { json: "subscriptions", js: "subscriptions", typ: u(undefined, u(a(r("SubscriptionV1")), null)) },
        { json: "todos", js: "todos", typ: u(undefined, u(null, r("TodosV2"))) },
        { json: "updated_at", js: "updated_at", typ: u(undefined, u(3.14, null)) },
        { json: "version", js: "version", typ: u(undefined, u(null, "")) },
    ], "any"),
    "ReminderV1": o([
        { json: "time", js: "time", typ: u(undefined, u(3.14, null)) },
        { json: "weekdays", js: "weekdays", typ: u(undefined, u(a(3.14), null)) },
    ], "any"),
    "TodosV2": o([
        { json: "active", js: "active", typ: u(undefined, u(m(r("DayTasksV1")), null)) },
        { json: "backlog", js: "backlog", typ: u(undefined, u(a(""), null)) },
        { json: "created_at", js: "created_at", typ: u(undefined, u(3.14, null)) },
        { json: "recently_deleted", js: "recently_deleted", typ: u(undefined, u(m(a(r("DeletedAt"))), null)) },
        { json: "tasks", js: "tasks", typ: u(undefined, u(m(r("StepV1")), null)) },
        { json: "updated_at", js: "updated_at", typ: u(undefined, u(3.14, null)) },
    ], "any"),
    "DayTasksV1": o([
        { json: "created_for", js: "created_for", typ: u(undefined, u(null, "")) },
        { json: "device_ids", js: "device_ids", typ: u(undefined, u(a(""), null)) },
        { json: "ends_at", js: "ends_at", typ: u(undefined, u(3.14, null)) },
        { json: "is_auto_review", js: "is_auto_review", typ: u(undefined, u(true, null)) },
        { json: "reviewed_at", js: "reviewed_at", typ: u(undefined, u(3.14, null)) },
        { json: "starts_at", js: "starts_at", typ: u(undefined, u(3.14, null)) },
        { json: "tasks", js: "tasks", typ: u(undefined, u(a(""), null)) },
    ], "any"),
    "DeletedAt": o([
        { json: "deleted_at", js: "deleted_at", typ: u(undefined, u(3.14, null)) },
        { json: "id", js: "id", typ: u(undefined, u(null, "")) },
    ], "any"),
    "TodosV1": o([
        { json: "active_todos", js: "active_todos", typ: u(undefined, u(null, r("DailyTodoV1"))) },
        { json: "backlog", js: "backlog", typ: u(undefined, u(m(r("StepV1")), null)) },
        { json: "backlog_ordering", js: "backlog_ordering", typ: u(undefined, u(a(""), null)) },
        { json: "created_at", js: "created_at", typ: u(undefined, u(3.14, null)) },
        { json: "device_id", js: "device_id", typ: u(undefined, u(null, "")) },
        { json: "updated_at", js: "updated_at", typ: u(undefined, u(3.14, null)) },
    ], "any"),
    "DailyTodoV1": o([
        { json: "created_for", js: "created_for", typ: u(undefined, u(null, "")) },
        { json: "expires_at", js: "expires_at", typ: u(undefined, u(3.14, null)) },
        { json: "tasks", js: "tasks", typ: u(undefined, u(a(r("StepV1")), null)) },
        { json: "updated_at", js: "updated_at", typ: u(undefined, u(3.14, null)) },
    ], "any"),
    "AndroidBehaviorV1": o([
        { json: "created_at", js: "created_at", typ: u(undefined, u(3.14, null)) },
        { json: "updated_at", js: "updated_at", typ: u(undefined, u(3.14, null)) },
    ], "any"),
    "DesktopBehaviorV1": o([
        { json: "created_at", js: "created_at", typ: u(undefined, u(3.14, null)) },
        { json: "updated_at", js: "updated_at", typ: u(undefined, u(3.14, null)) },
    ], "any"),
    "UserActivationV1": o([
        { json: "accomplishment_set", js: "accomplishment_set", typ: u(undefined, u(true, null)) },
        { json: "aha_moment_reached", js: "aha_moment_reached", typ: u(undefined, u(true, null)) },
        { json: "android_behavior", js: "android_behavior", typ: u(undefined, u(null, r("DeviceBehaviorV1"))) },
        { json: "channel", js: "channel", typ: u(undefined, u(null, "")) },
        { json: "chrome_behavior", js: "chrome_behavior", typ: u(undefined, u(null, r("DeviceBehaviorV1"))) },
        { json: "chrome_extension_installed", js: "chrome_extension_installed", typ: u(undefined, u(true, null)) },
        { json: "desktop_app_installed", js: "desktop_app_installed", typ: u(undefined, u(true, null)) },
        { json: "desktop_notification_enabled", js: "desktop_notification_enabled", typ: u(undefined, u(true, null)) },
        { json: "desktop_onboarding_finished", js: "desktop_onboarding_finished", typ: u(undefined, u(true, null)) },
        { json: "goal_set", js: "goal_set", typ: u(undefined, u(true, null)) },
        { json: "habit_moment_reached", js: "habit_moment_reached", typ: u(undefined, u(true, null)) },
        { json: "habit_set", js: "habit_set", typ: u(undefined, u(true, null)) },
        { json: "ios_behavior", js: "ios_behavior", typ: u(undefined, u(null, r("DeviceBehaviorV1"))) },
        { json: "ipad_behavior", js: "ipad_behavior", typ: u(undefined, u(null, r("DeviceBehaviorV1"))) },
        { json: "iwatch_behavior", js: "iwatch_behavior", typ: u(undefined, u(null, r("DeviceBehaviorV1"))) },
        { json: "last_seen_at", js: "last_seen_at", typ: u(undefined, u(3.14, null)) },
        { json: "mac_behavior", js: "mac_behavior", typ: u(undefined, u(null, r("DeviceBehaviorV1"))) },
        { json: "mobile_app_installed", js: "mobile_app_installed", typ: u(undefined, u(true, null)) },
        { json: "mobile_notification_enabled", js: "mobile_notification_enabled", typ: u(undefined, u(true, null)) },
        { json: "mobile_onboarding_finished", js: "mobile_onboarding_finished", typ: u(undefined, u(true, null)) },
        { json: "setup_moment_reached", js: "setup_moment_reached", typ: u(undefined, u(true, null)) },
        { json: "web_notification_enabled", js: "web_notification_enabled", typ: u(undefined, u(true, null)) },
        { json: "web_onboarding_finished", js: "web_onboarding_finished", typ: u(undefined, u(true, null)) },
        { json: "windows_behavior", js: "windows_behavior", typ: u(undefined, u(null, r("DeviceBehaviorV1"))) },
    ], "any"),
    "DeviceBehaviorV1": o([
        { json: "created_at", js: "created_at", typ: u(undefined, u(3.14, null)) },
        { json: "notification_enabled", js: "notification_enabled", typ: u(undefined, u(true, null)) },
    ], "any"),
    "UpdateUserRequestV1": o([
        { json: "accomplishments", js: "accomplishments", typ: u(undefined, u(a(r("AccomplishmentV1")), null)) },
        { json: "goals", js: "goals", typ: u(undefined, u(a(r("GoalV1")), null)) },
        { json: "habits", js: "habits", typ: u(undefined, u(a(r("HabitV1")), null)) },
        { json: "mindsets", js: "mindsets", typ: u(undefined, u(a(r("MindsetV1")), null)) },
        { json: "profile", js: "profile", typ: u(undefined, u(null, r("ProfileV1"))) },
    ], "any"),
    "InitialUserDataV1": o([
        { json: "accomplishment", js: "accomplishment", typ: u(undefined, "") },
        { json: "accomplishmentId", js: "accomplishmentId", typ: u(undefined, "") },
        { json: "goal", js: "goal", typ: u(undefined, "") },
        { json: "goalId", js: "goalId", typ: u(undefined, "") },
        { json: "habit", js: "habit", typ: u(undefined, "") },
        { json: "habitId", js: "habitId", typ: u(undefined, "") },
        { json: "mindset", js: "mindset", typ: u(undefined, "") },
        { json: "mindsetId", js: "mindsetId", typ: u(undefined, "") },
    ], "any"),
    "DeleteOrArchivePayload": o([
        { json: "archive", js: "archive", typ: u(undefined, true) },
        { json: "id", js: "id", typ: u(undefined, "") },
        { json: "type", js: "type", typ: u(undefined, r("ObjectType")) },
    ], "any"),
    "UnarchivePayload": o([
        { json: "id", js: "id", typ: u(undefined, "") },
        { json: "type", js: "type", typ: u(undefined, r("ObjectType")) },
    ], "any"),
    "UpdateOrderingPayload": o([
        { json: "newOrdering", js: "newOrdering", typ: u(undefined, a("")) },
        { json: "type", js: "type", typ: u(undefined, r("ObjectType")) },
    ], "any"),
    "AddProgressToGoalPayload": o([
        { json: "goalId", js: "goalId", typ: u(undefined, "") },
        { json: "newProgress", js: "newProgress", typ: u(undefined, u(3.14, null)) },
        { json: "newTotalProgress", js: "newTotalProgress", typ: u(undefined, u(3.14, null)) },
        { json: "steps", js: "steps", typ: u(undefined, a(r("StepV1"))) },
    ], "any"),
    "CheckInPayload": o([
        { json: "disable_capture", js: "disable_capture", typ: u(undefined, u(true, null)) },
        { json: "habitId", js: "habitId", typ: u(undefined, "") },
        { json: "media", js: "media", typ: u(undefined, u(null, r("MediaV1"))) },
        { json: "note", js: "note", typ: u(undefined, u(null, "")) },
        { json: "startOfDay", js: "startOfDay", typ: u(undefined, 3.14) },
    ], "any"),
    "UncheckInPayload": o([
        { json: "habitId", js: "habitId", typ: u(undefined, "") },
        { json: "startOfDay", js: "startOfDay", typ: u(undefined, 3.14) },
    ], "any"),
    "CheckInPayloadV2": o([
        { json: "disable_capture", js: "disable_capture", typ: u(undefined, u(true, null)) },
        { json: "endOfDay", js: "endOfDay", typ: u(undefined, 3.14) },
        { json: "habitId", js: "habitId", typ: u(undefined, "") },
        { json: "media", js: "media", typ: u(undefined, u(null, r("MediaV1"))) },
        { json: "note", js: "note", typ: u(undefined, u(null, "")) },
        { json: "startOfDay", js: "startOfDay", typ: u(undefined, 3.14) },
    ], "any"),
    "UncheckInPayloadV2": o([
        { json: "endOfDay", js: "endOfDay", typ: u(undefined, 3.14) },
        { json: "habitId", js: "habitId", typ: u(undefined, "") },
        { json: "startOfDay", js: "startOfDay", typ: u(undefined, 3.14) },
    ], "any"),
    "SubscriptionVerificationInfo": o([
        { json: "payload", js: "payload", typ: u(undefined, u(null, r("ValidatePurchasePayload"))) },
        { json: "product", js: "product", typ: u(undefined, u(null, "")) },
        { json: "purchase", js: "purchase", typ: u(undefined, u(null, r("PurchasedItem"))) },
        { json: "purchase_id", js: "purchase_id", typ: u(undefined, u(null, "")) },
        { json: "valid", js: "valid", typ: u(undefined, u(true, null)) },
    ], "any"),
    "ValidatePurchasePayload": o([
        { json: "method", js: "method", typ: u(undefined, r("SubscriptionMethod")) },
        { json: "product_id", js: "product_id", typ: u(undefined, "") },
        { json: "purchase_id", js: "purchase_id", typ: u(undefined, u(null, "")) },
        { json: "sandbox", js: "sandbox", typ: u(undefined, true) },
        { json: "validation_data", js: "validation_data", typ: u(undefined, "") },
    ], "any"),
    "PurchasedItem": o([
        { json: "appItemId", js: "appItemId", typ: u(undefined, "") },
        { json: "bundleId", js: "bundleId", typ: u(undefined, "") },
        { json: "cancellationDate", js: "cancellationDate", typ: u(undefined, u(3.14, null, "")) },
        { json: "expirationDate", js: "expirationDate", typ: u(undefined, u(3.14, null, "")) },
        { json: "isTrial", js: "isTrial", typ: u(undefined, true) },
        { json: "orderId", js: "orderId", typ: u(undefined, "") },
        { json: "originalPurchaseDate", js: "originalPurchaseDate", typ: u(undefined, "") },
        { json: "originalTransactionId", js: "originalTransactionId", typ: u(undefined, "") },
        { json: "productId", js: "productId", typ: u(undefined, "") },
        { json: "purchaseDate", js: "purchaseDate", typ: u(undefined, u(3.14, "")) },
        { json: "quantity", js: "quantity", typ: u(undefined, 3.14) },
        { json: "transactionId", js: "transactionId", typ: u(undefined, "") },
    ], "any"),
    "ValidatePurchasePayloadV2": o([
        { json: "purchases", js: "purchases", typ: u(undefined, a(r("ValidatePurchasePayload"))) },
    ], "any"),
    "ApplyPromoCodePayload": o([
        { json: "code", js: "code", typ: u(undefined, "") },
    ], "any"),
    "ApplyPromoCodeResponse": o([
        { json: "promo_code", js: "promo_code", typ: u(undefined, u(null, r("PromoCodeV1"))) },
        { json: "result", js: "result", typ: u(undefined, true) },
    ], "any"),
    "PromoCodeV1": o([
        { json: "applied_at", js: "applied_at", typ: u(undefined, u(3.14, null)) },
        { json: "applied_to", js: "applied_to", typ: u(undefined, u(null, "")) },
        { json: "campaign", js: "campaign", typ: u(undefined, u(null, "")) },
        { json: "code", js: "code", typ: u(undefined, u(null, "")) },
        { json: "created_at", js: "created_at", typ: u(undefined, 3.14) },
        { json: "duration_in_ms", js: "duration_in_ms", typ: u(undefined, u(3.14, null)) },
        { json: "product_id", js: "product_id", typ: u(undefined, u(null, "")) },
        { json: "promo_price", js: "promo_price", typ: u(undefined, u(3.14, null)) },
        { json: "vendor", js: "vendor", typ: u(undefined, u(null, "")) },
    ], "any"),
    "VerifyCodePayload": o([
        { json: "code", js: "code", typ: u(undefined, "") },
        { json: "email", js: "email", typ: u(undefined, "") },
    ], "any"),
    "NoPurchaseFoundPayload": o([
        { json: "method", js: "method", typ: u(undefined, r("SubscriptionMethod")) },
    ], "any"),
    "TodoAddTasksToDayPayload": o([
        { json: "command", js: "command", typ: u(undefined, r("TodoCommand")) },
        { json: "conceptual_date", js: "conceptual_date", typ: u(undefined, "") },
        { json: "tasks_to_add", js: "tasks_to_add", typ: u(undefined, a(r("StepV1"))) },
    ], "any"),
    "TodoUpdateTasksForDayPayload": o([
        { json: "command", js: "command", typ: u(undefined, r("TodoCommand")) },
        { json: "conceptual_date", js: "conceptual_date", typ: u(undefined, "") },
        { json: "merge", js: "merge", typ: u(undefined, true) },
        { json: "tasks_to_update", js: "tasks_to_update", typ: u(undefined, a(r("StepV1"))) },
    ], "any"),
    "TodoMoveTasksToDayPayload": o([
        { json: "command", js: "command", typ: u(undefined, r("TodoCommand")) },
        { json: "conceptual_date", js: "conceptual_date", typ: u(undefined, "") },
        { json: "tasks_to_move", js: "tasks_to_move", typ: u(undefined, a(r("StepV1"))) },
        { json: "todos", js: "todos", typ: u(undefined, r("TodosV2")) },
    ], "any"),
    "TodoAddTasksToBacklogPayload": o([
        { json: "command", js: "command", typ: u(undefined, r("TodoCommand")) },
        { json: "tasks_to_add", js: "tasks_to_add", typ: u(undefined, a(r("StepV1"))) },
        { json: "todos", js: "todos", typ: u(undefined, r("TodosV2")) },
    ], "any"),
    "TodoMoveTasksToBacklogPayload": o([
        { json: "command", js: "command", typ: u(undefined, r("TodoCommand")) },
        { json: "conceptual_date", js: "conceptual_date", typ: u(undefined, "") },
        { json: "tasks_to_move", js: "tasks_to_move", typ: u(undefined, a(r("StepV1"))) },
    ], "any"),
    "TodoDeleteTasksFromBacklogPayload": o([
        { json: "command", js: "command", typ: u(undefined, r("TodoCommand")) },
        { json: "tasks_to_delete", js: "tasks_to_delete", typ: u(undefined, a(r("StepV1"))) },
    ], "any"),
    "TodoUpdateBacklogOrderingPayload": o([
        { json: "backlog", js: "backlog", typ: u(undefined, m(r("StepV1"))) },
        { json: "backlog_ordering", js: "backlog_ordering", typ: u(undefined, a("")) },
        { json: "command", js: "command", typ: u(undefined, r("TodoCommand")) },
    ], "any"),
    "TodoSetActiveDayPayload": o([
        { json: "command", js: "command", typ: u(undefined, r("TodoCommand")) },
        { json: "conceptual_date", js: "conceptual_date", typ: u(undefined, "") },
    ], "any"),
    "UpdateTodosPayload": o([
        { json: "active_day_is_today", js: "active_day_is_today", typ: u(undefined, u(true, null)) },
        { json: "todos", js: "todos", typ: u(undefined, r("TodosV2")) },
    ], "any"),
    "MediaType": [
        "photo",
        "video",
    ],
    "ObjectType": [
        "accomplishment_v1",
        "goal_v1",
        "habit_v1",
        "mindset_v1",
        "profile_v1",
    ],
    "ActivityType": [
        "accomplishment:add",
        "accomplishment:delete",
        "accomplishment:update",
        "goal:add",
        "goal:archive",
        "goal:delete",
        "goal:progress",
        "goal:reached",
        "goal:unarchive",
        "goal:update",
        "habit:add",
        "habit:archive",
        "habit:checkin",
        "habit:complete",
        "habit:delete",
        "habit:unarchive",
        "habit:uncheckin",
        "habit:update",
        "mindset:add",
        "mindset:delete",
        "mindset:update",
        "profile:create",
    ],
    "MainCategory": [
        "career",
        "family",
        "financial",
        "fun",
        "health",
        "self",
        "social",
        "study",
    ],
    "ColorTheme": [
        "aqua",
        "beige",
        "blue",
        "boyish",
        "bright",
        "cafe",
        "dream",
        "fluorescent",
        "green",
        "lake",
        "lavendar",
        "lawn",
        "mars",
        "naked",
        "night",
        "omelette",
        "orange",
        "philosophy",
        "pink",
        "purple",
        "rain",
        "red",
        "romance",
        "silk",
        "silver",
        "soft",
        "steel",
        "warmth",
        "water",
        "yellow",
    ],
    "SecondaryCategory": [
        "business",
        "calm",
        "car",
        "children",
        "create",
        "credit",
        "debt",
        "dental",
        "diet",
        "fitness",
        "friends",
        "garden",
        "gratitude",
        "growth",
        "home",
        "invest",
        "journal",
        "leadership",
        "learning",
        "legacy",
        "love",
        "meditate",
        "mentor",
        "networking",
        "parent",
        "passive",
        "portfolio",
        "positive",
        "procrastinate",
        "productive",
        "promotion",
        "protein",
        "read",
        "routine",
        "save",
        "sleep",
        "train",
        "travel",
        "vacation",
        "vegetable",
        "weight",
        "write",
        "yoga",
    ],
    "GoalUnit": [
        "book",
        "chapter",
        "class",
        "course",
        "day",
        "euro",
        "gbp",
        "hour",
        "item",
        "km",
        "kg",
        "kusd",
        "lb",
        "mile",
        "minute",
        "page",
        "percent",
        "session",
        "step",
        "time",
        "usd",
        "video",
        "week",
        "word",
    ],
    "PeriodUnit": [
        "day",
        "month",
        "week",
    ],
    "HabitUnit": [
        "time",
    ],
    "MindsetTheme": [
        "default",
    ],
    "SubscriptionMethod": [
        "apple",
        "google",
        "grant",
        "gumroad",
        "promotion",
    ],
    "MindfulInAppPurchase": [
        "annual_subscription",
        "life_time_access",
        "life_time_access_2020",
        "monthly_subscription",
        "monthly_subscription_2020_03",
        "quarterly_subscription",
    ],
    "UserInfoVersion": [
        "v1",
    ],
    "TodoCommand": [
        "add_tasks_to_backlog",
        "add_tasks_to_day",
        "delete_task_from_backlog",
        "move_tasks_to_backlog",
        "move_tasks_to_day",
        "set_active_day",
        "update_backlog_ordering",
        "update_tasks_for_day",
    ],
};
