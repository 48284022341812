import React from "react";
import { useLocation, Link } from "@reach/router";
import classNames from "classnames";

import menuHome from "../img/menu-home.svg";
import menuHomeActive from "../img/menu-home-active.svg";
import menuGoals from "../img/menu-goals.svg";
import menuGoalsActive from "../img/menu-goals-active.svg";
import menuRoutines from "../img/menu-routines.svg";
import menuRoutinesActive from "../img/menu-routines-active.svg";
import menuNorthstar from "../img/menu-northstar.svg";
import menuNorthstarActive from "../img/menu-northstar-active.svg";
import menuSettings from "../img/menu-settings.svg";
import menuSettingsActive from "../img/menu-settings-active.svg";

import "./menu.sass";
import { RouteNames } from "../constants";
import { ProfilePresenter } from "../presenters";

export function MenuItem({
  item,
  activeItem,
  location,
  currentLocation,
}: {
  item: string;
  activeItem: string;
  location: string;
  currentLocation: string;
}) {
  const active = location === currentLocation;
  const className = classNames({
    menu__item: true,
    "menu__item--active": active,
  });
  return (
    <div className={className}>
      <Link to={location}>
        <img alt={location} src={active ? activeItem : item} />
      </Link>
    </div>
  );
}
export default function Menu({ profile }: { profile: ProfilePresenter | undefined }) {
  const location = useLocation();

  return (
    <div className="menu">
      <div className="logo h2">
        Mindful Suite
        <span className="beta">Beta</span>
      </div>
      <div className="menu__nav">
        <MenuItem
          item={menuHome}
          activeItem={menuHomeActive}
          location={RouteNames.Home}
          currentLocation={location.pathname}
        />
        <MenuItem
          item={menuGoals}
          activeItem={menuGoalsActive}
          location={RouteNames.Goals}
          currentLocation={location.pathname}
        />
        <MenuItem
          item={menuRoutines}
          activeItem={menuRoutinesActive}
          location={RouteNames.Routines}
          currentLocation={location.pathname}
        />
        <MenuItem
          item={menuNorthstar}
          activeItem={menuNorthstarActive}
          location={RouteNames.NorthStar}
          currentLocation={location.pathname}
        />
        <MenuItem
          item={menuSettings}
          activeItem={menuSettingsActive}
          location={RouteNames.Settings}
          currentLocation={location.pathname}
        />
      </div>
      <div className="menu__profile-box">
        <div className="large">{profile?.displayName || "My Goals"}</div>
        <div className="hsep"></div>
        <div className="avatar">
          <img
            alt="Profile picture"
            src={
              profile?.profileURL ||
              "https://cms.qz.com/wp-content/uploads/2017/03/twitter_egg_blue.png?w=900&h=900&crop=1&strip=all&quality=75"
            }
          />
        </div>
      </div>
    </div>
  );
}
