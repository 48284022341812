import firebase, { firestore } from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

import { docData, collectionData } from 'rxfire/firestore';
import { MindfulGoalUser, Convert, ActivityV1, MindfulTodoUser } from "./models";
import { Observable } from "rxjs";
import { debounceTime } from "rxjs/operators";

const app = firebase.initializeApp({
  apiKey: "AIzaSyAFXQzEhE9db_yQr3nByTF7VaP8uAoiJHI",
  authDomain: "mindful-e8ef6.firebaseapp.com",
  databaseURL: "https://mindful-e8ef6.firebaseio.com",
  projectId: "mindful-e8ef6",
  storageBucket: "mindful-e8ef6.appspot.com",
  messagingSenderId: "461951599387",
  appId: "1:461951599387:web:4cef57d611889fd0",
});

export const db = firebase.firestore();
export const storage = firebase.storage();

export const refToUserData = (uid: string): Observable<MindfulGoalUser> => {
  return docData<MindfulGoalUser>(db.collection("mindful_user_data").doc(uid).withConverter(
    {
      toFirestore(user: MindfulGoalUser): firestore.DocumentData {
        return JSON.parse(Convert.mindfulGoalUserToJson(user));
      },
      fromFirestore(
        snapshot: firestore.QueryDocumentSnapshot,
        options: firestore.SnapshotOptions
      ): MindfulGoalUser {
        const data = snapshot.data(options);
        const result = data as MindfulGoalUser;
        return result
      }
    }
  )).pipe(debounceTime<MindfulGoalUser>(1000));
};

export const refToUserTodos = (uid: string): Observable<MindfulTodoUser> => {
  return docData<MindfulTodoUser>(db.collection("mindful_user_todos").doc(uid).withConverter(
    {
      toFirestore(user: MindfulTodoUser): firestore.DocumentData {
        return JSON.parse(Convert.mindfulTodoUserToJson(user));
      },
      fromFirestore(
        snapshot: firestore.QueryDocumentSnapshot,
        options: firestore.SnapshotOptions
      ): MindfulTodoUser {
        const data = snapshot.data(options);
        return Convert.toMindfulTodoUser(JSON.stringify(data));
      }
    }
  )).pipe(debounceTime<MindfulTodoUser>(1000));
};

export const refToUserActivities = (uid: string): Observable<ActivityV1[]> => {
  return collectionData(db.collection("mindful_user_activities").doc(uid).collection("activities").withConverter({
    toFirestore(activity: ActivityV1): firestore.DocumentData {
      return JSON.parse(Convert.activityV1ToJson(activity));
    },
    fromFirestore(
      snapshot: firestore.QueryDocumentSnapshot,
      options: firestore.SnapshotOptions
    ): ActivityV1 {
      const data = snapshot.data(options);
      return Convert.toActivityV1(JSON.stringify(data));
    }
  }));
};

export default app;
