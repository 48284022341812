import { HabitV1, PeriodUnit, CheckInV1 } from "./models";
import { startOfDateTime2Int, safeStartOfLastWeek, safeStartOfPreviousDay, endOfDateTime2Int } from "./utils";
import { differenceInDays } from "date-fns";

export class HabitStats {
  habit: HabitV1;
  weekUnit: string;

  habitPeriod!: PeriodUnit;
  // Days left in the period
  daysLeft!: number;
  // if the habit is done in the period.
  isDone!: boolean;
  // How many check ins to go in the period
  checkInsToGo!: number;
  // if the habit reached daily goal (checked in for weekly goals or meet daily limit).
  completedToday!: boolean;
  // if the user has checked in today
  checkedInToday!: boolean;
  // if the user can continue to check in
  canContinue!: boolean;

  // checks ins based on selected unit
  checkInsThisPeriod!: CheckInV1[];
  numCheckInsThisPeriod!: number;
  targetFrequency!: number;
  completion!: number;
  completionPercent!: number;

  // checks ins in the week
  checkInsThisWeek!: CheckInV1[];
  numCheckInsThisWeek!: number;
  weeklyTargetFrequency!: number;
  weeklyCompletion!: number;
  weeklyCompletionPercent!: number;

  groupByDay!: {
    [id: number]: CheckInV1[];
  };
  dayStreak!: number;
  groupByWeek!: {
    [id: number]: CheckInV1[];
  };
  weekStreak!: number;
  weekStreakAssumeCurrent!: number;

  averages8!: number[];
  averages9!: number[];
  average!: number;
  averagePercent!: string;
  averageTrend!: number;

  frequencySummary!: string;

  constructor(habit: HabitV1, weekUnit: string = "sundayWeek") {
    this.habit = habit;
    this.weekUnit = weekUnit;

    this._snapHabitPeriod();
    this._snapGroupByDay();
    this._snapGroupByWeek();

    this._snapDaysLeft();

    this._snapCheckedInToday();
    this._snapCanContinue();

    this._snapCheckInsThisPeriod();
    this._snapNumCheckInsThisPeriod();
    this._snapCheckInsToGoThisPeriod();
    this._snapIsDone();
    this._snapCompletedToday();
    this._snapTargetFrequency();
    this._snapCompletion();
    this._snapCompletionPercent();
    this._snapCheckInsThisWeek();
    this._snapNumCheckInsThisWeek();
    this._snapWeeklyTargetFrequency();
    this._snapWeeklyCompletion();
    this._snapWeeklyCompletionPercent();

    this._snapDayStreak();
    this._snapWeekStreak();
    this._snapWeekStreakAssumeCurrent();
    this._snapAverages8();
    this._snapAverages9();
    this._snapAverage();
    this._snapAveragePercent();
    this._snapAverageTrend();

    this._snapFrequencySummary();
  }

  _snapHabitPeriod(): PeriodUnit {
    return this.habitPeriod !== undefined ? this.habitPeriod : this.habitPeriod = (!this.habit || !this.habit.period) ? PeriodUnit.Week : this.habit.period;
  }

  _snapDaysLeft(): number {
    return this.daysLeft !== undefined ? this.daysLeft : this.daysLeft = this.calcDaysLeftInPeriod(this._snapHabitPeriod(), new Date());
  }

  _snapIsDone(): boolean {
    return this.isDone !== undefined ? this.isDone : this.isDone = this._snapCheckInsToGoThisPeriod() <= 0;
  }

  _snapCheckInsToGoThisPeriod(): number {
    if (this.checkInsToGo !== undefined) {
      return this.checkInsToGo;
    }
    const target = this._snapTargetFrequency();
    const numCheckIns = this._snapNumCheckInsThisPeriod();
    return this.checkInsToGo = target - numCheckIns;
  }

  _snapTargetFrequency(): number {
    return this.targetFrequency !== undefined ? this.targetFrequency : this.targetFrequency = (this.habit.frequency ?? 1) | 0;
  }

  _snapCompletion(): number {
    if (this.completion !== undefined) {
      return this.completion;
    }
    const target = this._snapTargetFrequency();
    if (target === 0) {
      return 0;
    }
    return this.completion = Math.min(1, (this._snapNumCheckInsThisPeriod() * 1.0) / target);
  }

  _snapCompletionPercent(): number {
    return this.completionPercent !== undefined ? this.completionPercent : this.completionPercent = Math.round(Math.max(0, this._snapCompletion() * 100));
  }

  _snapNumCheckInsThisPeriod(): number {
    return this.numCheckInsThisPeriod !== undefined ? this.numCheckInsThisPeriod : this.numCheckInsThisPeriod = this._snapCheckInsThisPeriod().length;
  }

  _snapCheckInsThisPeriod(): CheckInV1[] {
    return this.checkInsThisPeriod !== undefined ? this.checkInsThisPeriod : this.checkInsThisPeriod = this.calcCheckInsThisPeriod();
  }

  _snapCheckInsThisWeek(): CheckInV1[] {
    return this.checkInsThisWeek !== undefined ? this.checkInsThisWeek : this.checkInsThisWeek = this._snapHabitPeriod() === PeriodUnit.Week ?
      this._snapCheckInsThisPeriod() :
      this.calcCheckInsThisPeriod(new Date(), PeriodUnit.Week);
  }

  _snapNumCheckInsThisWeek(): number {
    return this.numCheckInsThisWeek !== undefined ? this.numCheckInsThisWeek : this.numCheckInsThisWeek = this._snapCheckInsThisWeek().length;
  }

  // What's the target frequency when we calculate progress.
  // It may be different from a display frequency for daily habits,
  // which may be daily, once a day, 1x a day, 7 times a week, or 7x a week.
  _snapWeeklyTargetFrequency(): number {
    return this.weeklyTargetFrequency !== undefined ? this.weeklyTargetFrequency : this.weeklyTargetFrequency = this._snapHabitPeriod() === PeriodUnit.Week ?
      this._snapTargetFrequency() :
      this.calcWeeklyTargetFrequency();
  }

  _snapWeeklyCompletion(): number {
    if (this.weeklyCompletion !== undefined) {
      return this.weeklyCompletion;
    }

    let result: number = 0;
    if (this._snapHabitPeriod() === PeriodUnit.Week) {
      result = this._snapCompletion();
    } else {
      const target = this._snapWeeklyTargetFrequency();
      if (target === 0) {
        result = 0;
      } else {
        result = Math.min(1, this._snapNumCheckInsThisWeek() / target);
      }
    }
    return this.weeklyCompletion = result;
  }

  _snapWeeklyCompletionPercent(): number {
    return this.weeklyCompletionPercent !== undefined ? this.weeklyCompletionPercent : this.weeklyCompletionPercent = this._snapHabitPeriod() === PeriodUnit.Week ?
      this._snapCompletionPercent() :
      Math.round(Math.max(0, this._snapWeeklyCompletion() * 100));
  }

  _snapGroupByDay(): { [id: number]: CheckInV1[] } {
    if (this.groupByDay) {
      return this.groupByDay;
    }
    this.groupByDay = (this.habit.checkins ?? []).reduce((map: { [id: number]: CheckInV1[] }, checkIn: CheckInV1) => {
      const bucket = this.firstDayOfPeriod(PeriodUnit.Day, new Date(checkIn.created_at!));
      if (map[bucket]) {
        map[bucket].push(checkIn);
      } else {
        map[bucket] = [checkIn];
      }
      return map;
    }, {});
    return this.groupByDay;
  }

  _snapDayStreak(): number {
    return this.dayStreak !== undefined ? this.dayStreak : this.dayStreak = this.calcDayStreak(false);
  }

  _snapCheckedInToday(): boolean {
    return this.checkedInToday !== undefined ? this.checkedInToday : this.checkedInToday = this.calcCheckedInOnDate(new Date());
  }

  _snapCanContinue() {
    return this.canContinue !== undefined ? this.canContinue : this.canContinue = this.habit.period === PeriodUnit.Day;
  }

  calcCompletedInPeriod(date: Date): boolean {
    return this.calcCheckInsOnDate(date).length >= (this.habit.frequency ?? 1);
  }

  _snapCompletedToday(): boolean {
    return this.completedToday !== undefined ? this.completedToday : this.completedToday = this.calcHabitDoneForTheDay(this.calcCheckInsOnDate(new Date()).length);
  }

  calcWeeklyTargetFrequency(): number {
    const frequency = this.habit?.frequency ?? 1;
    return this._snapHabitPeriod() === PeriodUnit.Day ? frequency * 7 : frequency;
  }

  calcCheckedInOnDate(date: Date): boolean {
    return this.calcCheckInsOnDate(date).length !== 0;
  }

  calcCheckInsOnDate(date: Date): CheckInV1[] {
    if (!date) {
      date = new Date();
    }
    const startOfDay = startOfDateTime2Int(date, "day");
    return this.groupByDay[startOfDay] ?? [];
  }

  calcCheckInsThisPeriod(now: Date = new Date(), periodOverride: PeriodUnit | undefined | null = null): CheckInV1[] {
    if (!this.habit.checkins || this.habit.checkins.length === 0) {
      return [];
    }
    const firstDayStamp = this.firstDayOfPeriod(periodOverride ?? this._snapHabitPeriod(), now);
    const result: CheckInV1[] = [];
    const reversed = [...this.habit.checkins].reverse();
    for (let checkIn of reversed) {
      if (checkIn.created_at && checkIn.created_at < firstDayStamp) {
        break;
      }
      result.push(checkIn);
    }
    return result.reverse();
  }

  calcHabitDoneForTheDay(checkIns: number) {
    return (this.habit.period === PeriodUnit.Day && checkIns >= (this.habit.frequency ?? 1)) ||
      (this.habit.period !== PeriodUnit.Day && checkIns >= 1);
  }

  calcDayStreak(assumeToday: boolean = false): number {
    if (!this.habit.checkins || this.habit.checkins.length === 0) {
      return assumeToday ? 1 : 0;
    }

    const now = new Date();

    // use 6 days and 12 hours instead of 7 days to work around Day light saving week when it's shorter.
    const earliestCheckIn = this.firstDayOfPeriod(PeriodUnit.Day, new Date(this.habit.checkins[0]!.created_at!));
    let startOfDay = startOfDateTime2Int(now, "day");

    const target = this._snapHabitPeriod() === PeriodUnit.Day ? this.habit.frequency ?? 1 : 1;
    let streak = assumeToday ? 1 : this.reachedTarget(this._snapGroupByDay()[startOfDay.valueOf()], target) ? 1 : 0;
    startOfDay = safeStartOfPreviousDay(new Date(startOfDay), 1);

    while (startOfDay >= earliestCheckIn) {
      const checkInsForTheWeek = this._snapGroupByDay()[startOfDay];
      if (!this.reachedTarget(checkInsForTheWeek, target)) {
        break;
      }
      streak++;
      startOfDay = safeStartOfPreviousDay(new Date(startOfDay), 1);
    }

    return streak;
  }

  _snapWeekStreak(): number {
    return this.weekStreak !== undefined ? this.weekStreak : this.weekStreak = this.calcWeekStreak(false);
  }

  _snapWeekStreakAssumeCurrent(): number {
    return this.weekStreakAssumeCurrent !== undefined ? this.weekStreakAssumeCurrent : this.weekStreakAssumeCurrent = this.calcWeekStreak(true);
  }

  calcWeekStreak(assumeCurrentWeek: boolean = false) {
    if (!this.habit.checkins || this.habit.checkins.length === 0) {
      return assumeCurrentWeek ? 1 : 0;
    }

    const now = new Date();
    // use 6 days and 12 hours instead of 7 days to work around Day light saving week when it's shorter.
    const earliestCheckIn = this.firstDayOfPeriod(PeriodUnit.Week, new Date(this.habit.checkins[0].created_at!));
    let startOfWeek = startOfDateTime2Int(now, this.weekUnit);

    const target = this.calcWeeklyTargetFrequency();
    let streak = assumeCurrentWeek ? 1 : this.reachedTarget(this._snapGroupByWeek()[startOfWeek], target) ? 1 : 0;
    startOfWeek = safeStartOfLastWeek(startOfWeek, this.weekUnit);

    while (startOfWeek >= earliestCheckIn) {
      const checkInsForTheWeek = this._snapGroupByWeek()[startOfWeek];
      if (!this.reachedTarget(checkInsForTheWeek, target)) {
        break;
      }
      streak++;
      startOfWeek = safeStartOfLastWeek(startOfWeek, this.weekUnit);
    }

    return streak;
  }

  reachedTarget(checkIns: CheckInV1[], target: number): boolean {
    if (!checkIns || checkIns.length === 0 || checkIns.length < target) {
      return false;
    }
    return true;
  }

  _snapGroupByWeek(): { [id: number]: CheckInV1[] } {
    if (this.groupByWeek !== undefined) {
      return this.groupByWeek;
    }
    return this.groupByWeek = (this.habit.checkins ?? []).reduce((map: { [id: number]: CheckInV1[] }, checkIn: CheckInV1) => {
      const bucket = this.firstDayOfPeriod(PeriodUnit.Week, new Date(checkIn.created_at!));
      if (map[bucket]) {
        map[bucket].push(checkIn);
      } else {
        map[bucket] = [checkIn];
      }
      return map;
    }, {});
  }

  _snapAverages8(): number[] {
    return this.averages8 !== undefined ? this.averages8 : this.averages8 = this.calcAverages(this._snapGroupByWeek(), 8);
  }

  _snapAverages9(): number[] {
    return this.averages9 !== undefined ? this.averages9 : this.averages9 = this.calcAverages(this._snapGroupByWeek(), 9);
  }

  _snapAverage(): number {
    return this.average !== undefined ? this.average : this.average = this.calcHabitAverage(this._snapAverages8());
  }

  _snapAveragePercent(): string {
    return this.averagePercent !== undefined ? this.averagePercent : this.averagePercent = this.calcHabitAveragePercent(this._snapAverages8());
  }

  _snapAverageTrend(): number {
    return this.averageTrend !== undefined ? this.averageTrend : this.averageTrend = this.calcAverageTrend(this._snapAverages8(), this._snapAverages9());
  }

  calcHabitAveragePercent(averages: number[]): string {
    if (averages.length === 0) {
      return "-";
    }
    const average = Math.round((100 * averages.reduce((total, e) => total + e, 0.0)) / averages.length);
    return `${average}%`;
  }

  calcHabitAverage(averages: number[]): number {
    if (averages.length === 0) {
      return 0.0;
    }
    return averages.reduce((total, e) => total + e, 0) / averages.length;
  }

  calcAverages(groupByWeek: { [id: number]: CheckInV1[] }, weeks: number): number[] {
    if (!this.habit.checkins || this.habit.checkins.length === 0) {
      return [];
    }

    const habitStart = this.calcHabitStart();
    const result: number[] = [];
    const now = new Date();
    const earliestCheckIn = startOfDateTime2Int(new Date(habitStart), this.weekUnit);
    let startOfLastWeek = safeStartOfLastWeek(now.valueOf(), this.weekUnit);

    while (startOfLastWeek >= earliestCheckIn && result.length < weeks) {
      const checkInsForTheWeek = groupByWeek[startOfLastWeek] ?? [];
      const target = this.calcWeeklyTargetFrequency();
      if (target === 0) {
        result.push(0);
      } else {
        result.push(Math.min(1, (checkInsForTheWeek.length * 1.0) / target));
      }
      startOfLastWeek = safeStartOfLastWeek(startOfLastWeek, this.weekUnit);
    }

    return result.reverse();
  }

  calcAverageTrend(averages8: number[], averages9: number[]) {
    if (averages9.length === 0 || averages8.length === 0) {
      return 0.0;
    }

    // on first week, trend is 0 -> first week's ratio
    if (averages9.length === 1 || averages8.length === 1) {
      return averages8[0];
    }

    if (averages9.length < 9) {
      // we haven't reached 8 weeks yet. not rolling average;
      // avg(A_1, A_n) - avg(A_1 - A_n-1)
      const avg9 = averages9.slice(0, averages9.length - 1).reduce((total, e) => total + e, 0) / (averages9.length - 1);
      const avg8 = averages8.reduce((total, e) => total + e, 0) / averages8.length;
      return avg8 - avg9;
    }

    const avg9 = averages9.slice(0, 8).reduce((total, e) => total + e, 0) / 8;
    const avg8 = averages8.reduce((total, e) => total + e, 0) / 8;
    return avg8 - avg9;
  }

  calcHabitStart(): number {
    if (!this.habit || !this.habit.created_at) {
      return new Date().valueOf();
    }
    if (!this.habit.checkins || this.habit.checkins.length === 0 || !this.habit.checkins[0].created_at) {
      return this.habit.created_at;
    }
    return Math.min(this.habit.created_at, this.habit.checkins[0].created_at);
  }

  firstDayOfPeriod(period: PeriodUnit, now: Date): number {
    switch (period) {
      case PeriodUnit.Month:
        return startOfDateTime2Int(now, "month");
      case PeriodUnit.Day:
        return startOfDateTime2Int(now, "day");
      default:
        return startOfDateTime2Int(now, this.weekUnit);
    }
  }

  lastDayOfPeriod(period: PeriodUnit, now: Date): number {
    switch (period) {
      case PeriodUnit.Month:
        return endOfDateTime2Int(now, "month");
      case PeriodUnit.Day:
        return endOfDateTime2Int(now, "day");
      default:
        return endOfDateTime2Int(now, this.weekUnit);
    }
  }

  _snapFrequencySummary(): string {
    if (this.frequencySummary !== undefined) {
      return this.frequencySummary;
    }
    const frequency = this.habit.frequency ?? 1;
    const period = this._snapHabitPeriod();
    return this.frequencySummary = `${frequency}X / ${period}`.toUpperCase();
  }

  calcDaysLeftInPeriod(period: PeriodUnit, now: Date): number {
    now = now ?? new Date();
    const lastDayInPeriod = new Date(this.lastDayOfPeriod(period, now));
    return differenceInDays(lastDayInPeriod, now);
  }
}
