import React, { useMemo } from "react";
import { render } from "react-dom";
import { Router } from "@reach/router";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import { store } from "./app/store";

import App from "./app";
import SignIn from "./app/onboarding/sign_in";
import { MessageFormatElement } from "intl-messageformat-parser";

import en from "./compiled-lang/en.json";

import * as serviceWorker from "./serviceWorker";
import { Colors } from "./variables";

import "./index.css";
import { RouteNames } from "./constants";
import Dashboard from "./components/dashboard";
import Goals from "./components/goals";
import Habits from "./components/habits";
import Mindsets from "./components/mindsets";

declare global {
  interface Window {
    app: any;
  }
}

const language = navigator.language.split(/[-_]/)[0];

function loadLocaleData(locale: string) {
  switch (locale) {
    default:
      return en;
  }
}

function Root({ messages, locale }: { messages: Record<string, MessageFormatElement[]>; locale: string }) {
  const theme = useMemo(
    () =>
      createMuiTheme({
        props: {
          MuiButtonBase: {
            disableRipple: true,
          },
        },
        palette: {
          type: "dark",
          primary: {
            main: Colors.primaryBlue,
            contrastText: Colors.offWhite,
          },
          background: {
            default: Colors.appBlack,
            paper: Colors.offBlack,
          },
        },
        typography: {
          fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            "Oxygen-Sans",
            "Ubuntu",
            "Cantarell",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(","),
          fontWeightRegular: 500,
        },
      }),
    []
  );

  return (
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <IntlProvider locale={locale} defaultLocale="en" messages={messages}>
            <Router>
              <App path={RouteNames.Home}>
                <Dashboard path="/" />
                <Goals path={RouteNames.Goals} />
                <Habits path={RouteNames.Routines} />
                <Mindsets path={RouteNames.NorthStar} visionBoard />
              </App>
              <SignIn path={RouteNames.SignIn} />
            </Router>
          </IntlProvider>
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  );
}

async function bootstrapApplication(locale: string) {
  const messages = loadLocaleData(locale);
  render(<Root messages={messages} locale={locale} />, document.getElementById("root"));
}

bootstrapApplication(language);

serviceWorker.unregister();
