import {
  startOfYear,
  startOfQuarter,
  startOfMonth,
  startOfWeek,
  startOfDay,
  startOfHour,
  startOfMinute,
  startOfSecond,
  endOfYear,
  endOfQuarter,
  endOfMinute,
  endOfMonth,
  endOfWeek,
  endOfDay,
  endOfHour,
  endOfSecond,
} from "date-fns";
import {
  HabitV1,
  GoalV1,
  MindsetV1,
  AccomplishmentV1,
  UserInfoV1,
  ColorTheme,
  GoalUnit,
  MainCategory,
} from "./models";

export function logError(error: any) {
  console.log(`Error: ${error}`);
}

export function logInfo(message: string) {
  console.log(`Info: ${message}`);
}

type OptionalNumber = number | null | undefined;
type OptionalIds = string[] | null | undefined;
type OptionalGoalsMap = { [id: string]: GoalV1 } | null | undefined;
type OptionalHabitsMap = { [id: string]: HabitV1 } | null | undefined;
type OptionalMindsetsMap = { [id: string]: MindsetV1 } | null | undefined;
type OptionalAccomplishmentsMap = { [id: string]: AccomplishmentV1 } | null | undefined;
export type OptionalUserInfoV1 = UserInfoV1 | null | undefined;

export function safeHabits(habitOrdering: OptionalIds, habits: OptionalHabitsMap): HabitV1[] {
  if (!habits || !habitOrdering) {
    return [];
  }
  return habitOrdering.map((id) => habits[id]).filter((i) => Boolean(i));
}

export function safeGoals(goalOrdering: OptionalIds, goals: OptionalGoalsMap): GoalV1[] {
  if (!goals || !goalOrdering) {
    return [];
  }
  return goalOrdering.map((id) => goals[id]).filter((i) => Boolean(i));
}

export function safeMindses(mindsetOrdering: OptionalIds, mindsets: OptionalMindsetsMap): MindsetV1[] {
  if (!mindsets || !mindsetOrdering) {
    return [];
  }
  return mindsetOrdering.map((id) => mindsets[id]).filter((i) => Boolean(i));
}

export function safeAccomplishments(
  accomplishmentOrdering: OptionalIds,
  accomplishments: OptionalAccomplishmentsMap
): AccomplishmentV1[] {
  if (!accomplishments || !accomplishmentOrdering) {
    return [];
  }
  return accomplishmentOrdering.map((id) => accomplishments[id]).filter((i) => Boolean(i));
}

class Swatch {
  main: string;
  off: string;
  light: string;
  dark: string;
  constructor(main: string, off: string, light: string, dark: string) {
    this.main = main;
    this.off = off;
    this.light = light;
    this.dark = dark;
  }
}

export const ColorThemeToSwatch: { [id: string]: Swatch } = {};
ColorThemeToSwatch[ColorTheme.Blue] = new Swatch("#066DFE", "#5A3EFF", "#589DFF", "#004DB8");
ColorThemeToSwatch[ColorTheme.Green] = new Swatch("#57AC40", "#ADD700", "#7DDD61", "#289F36");
ColorThemeToSwatch[ColorTheme.Orange] = new Swatch("#FF6B1C", "#FF491A", "#FF8B4D", "#FF490F");
ColorThemeToSwatch[ColorTheme.Purple] = new Swatch("#671DD3", "#4200AA", "#8633FF", "#42059A");
ColorThemeToSwatch[ColorTheme.Red] = new Swatch("#FA2835", "#FF6059", "#FF5555", "#EE0000");
ColorThemeToSwatch[ColorTheme.Yellow] = new Swatch("#F7B10C", "#FFC641", "#FFD300", "#FFA000");
ColorThemeToSwatch[ColorTheme.Aqua] = new Swatch("#07C49B", "#0BF0BE", "#46C999", "#069694");
ColorThemeToSwatch[ColorTheme.Pink] = new Swatch("#FF236F", "#FF4284", "#E75C8C", "#E50560");
ColorThemeToSwatch[ColorTheme.Night] = new Swatch("#2C2E69", "#22245E", "#2C2E69", "#221256");
ColorThemeToSwatch[ColorTheme.Philosophy] = new Swatch("#2C2A24", "#6A5422", "#60553C", "#3B3730");
ColorThemeToSwatch[ColorTheme.Water] = new Swatch("#99ABFF", "#99ABFF", "#99ABFF", "#99ABFF");
ColorThemeToSwatch[ColorTheme.Lawn] = new Swatch("#98BD82", "#98BD82", "#98BD82", "#98BD82");
ColorThemeToSwatch[ColorTheme.Naked] = new Swatch("#FC988A", "#FC988A", "#FC988A", "#FC988A");
ColorThemeToSwatch[ColorTheme.Silver] = new Swatch("#B5B3B3", "#B5B3B3", "#B5B3B3", "#B5B3B3");
ColorThemeToSwatch[ColorTheme.Warmth] = new Swatch("#FF7160", "#FF7160", "#FF7160", "#FF7160");
ColorThemeToSwatch[ColorTheme.Omelette] = new Swatch("#FBCA75", "#FBCA75", "#FBCA75", "#FBCA75");
ColorThemeToSwatch[ColorTheme.Rain] = new Swatch("#86C2B6", "#86C2B6", "#86C2B6", "#86C2B6");
ColorThemeToSwatch[ColorTheme.Romance] = new Swatch("#FF98B7", "#FF98B7", "#FF98B7", "#FF98B7");
ColorThemeToSwatch[ColorTheme.Lavendar] = new Swatch("#6A6693", "#6A6693", "#6A6693", "#6A6693");
ColorThemeToSwatch[ColorTheme.Beige] = new Swatch("#C2B59D", "#C2B59D", "#C2B59D", "#C2B59D");
ColorThemeToSwatch[ColorTheme.Soft] = new Swatch("#DEC9D2", "#DEC9D2", "#DEC9D2", "#DEC9D2");
ColorThemeToSwatch[ColorTheme.Mars] = new Swatch("#D19C8A", "#D19C8A", "#D19C8A", "#D19C8A");
ColorThemeToSwatch[ColorTheme.Fluorescent] = new Swatch("#C7E0C3", "#C7E0C3", "#C7E0C3", "#C7E0C3");
ColorThemeToSwatch[ColorTheme.Silk] = new Swatch("#DBCDF7", "#DBCDF7", "#DBCDF7", "#DBCDF7");
ColorThemeToSwatch[ColorTheme.Cafe] = new Swatch("#A1907E", "#A1907E", "#A1907E", "#A1907E");
ColorThemeToSwatch[ColorTheme.Dream] = new Swatch("#F2C5D7", "#F2C5D7", "#F2C5D7", "#F2C5D7");
ColorThemeToSwatch[ColorTheme.Lake] = new Swatch("#BCD2FD", "#BCD2FD", "#BCD2FD", "#BCD2FD");
ColorThemeToSwatch[ColorTheme.Boyish] = new Swatch("#9DD8DE", "#9DD8DE", "#9DD8DE", "#9DD8DE");
ColorThemeToSwatch[ColorTheme.Steel] = new Swatch("#A2B4C0", "#A2B4C0", "#A2B4C0", "#A2B4C0");
ColorThemeToSwatch[ColorTheme.Bright] = new Swatch("#F3E396", "#F3E396", "#F3E396", "#F3E396");

export function goalPercentCompletion(g: GoalV1): number {
  if (!g) {
    return 0;
  }

  if (isEnumeratableUnit(g.unit)) {
    // use completed steps / total steps as percent;
    if (!g.steps || g.steps.length === 0) {
      return 0;
    }

    const completed = g.steps.filter((s) => s.complete === true).length;
    return safePercent(completed, g.steps.length);
  }

  return safePercent(g.current_progress, g.total_estimate);
}

export function timestampStr(): string { return `${new Date().valueOf()}`; }
export function timestampDouble(): number { return new Date().valueOf(); }

export function safePercent(done: OptionalNumber, total: OptionalNumber) {
  if (!total || !done || total === 0) {
    return 0;
  }

  return Math.max(0, Math.min(100, ((100 * done) / total) | 0));
}

export function startOfDateTime2Int(now: Date, units: string): number {
  let time: number;
  const date = now ?? new Date();
  if (!units || units === "millisecond") {
    return date.valueOf();
  }

  switch (units) {
    case "year":
      time = startOfYear(date).valueOf();
      break;
    case "quarter":
      time = startOfQuarter(date).valueOf();
      break;
    case "month":
      time = startOfMonth(date).valueOf();
      break;
    case "week":
      time = startOfWeek(date, { weekStartsOn: 1 }).valueOf();
      break;
    case "sundayWeek":
      time = startOfWeek(date).valueOf();
      break;
    case "day":
    case "date":
      time = startOfDay(date).valueOf();
      break;
    case "hour":
      time = startOfHour(date).valueOf();
      break;
    case "minute":
      time = startOfMinute(date).valueOf();
      break;
    case "second":
      time = startOfSecond(date).valueOf();
      break;
    default:
      time = date.valueOf();
      break;
  }
  return time;
}

export function endOfDateTime2Int(now: Date, units: string): number {
  let time: number;
  const date = now ?? new Date();
  if (!units || units === "millisecond") {
    return date.valueOf();
  }

  switch (units) {
    case "year":
      time = endOfYear(date).valueOf();
      break;
    case "quarter":
      time = endOfQuarter(date).valueOf();
      break;
    case "month":
      time = endOfMonth(date).valueOf();
      break;
    case "week":
      time = endOfWeek(date, { weekStartsOn: 1 }).valueOf();
      break;
    case "sundayWeek":
      time = endOfWeek(date).valueOf();
      break;
    case "day":
    case "date":
      time = endOfDay(date).valueOf();
      break;
    case "hour":
      time = endOfHour(date).valueOf();
      break;
    case "minute":
      time = endOfMinute(date).valueOf();
      break;
    case "second":
      time = endOfSecond(date).valueOf();
      break;
    default:
      time = date.valueOf();
      break;
  }
  return time;
}

export function safeNextDay(day: Date, days: number): number {
  const noon = new Date(day.getFullYear(), day.getMonth(), day.getDate(), 12).valueOf();
  // add 16 hours at noon = 4pm the next day. then find the start of the day. this should be able to avoid DST.
  return startOfDateTime2Int(new Date(noon + (24 * (days - 1) + 16) * 3600 * 1000), "day");
}

export function safeStartOfPreviousDay(day: Date, days: number): number {
  const noon = new Date(day.getFullYear(), day.getMonth(), day.getDate(), 12).valueOf();
  return startOfDateTime2Int(new Date(noon - (24 * (days - 1) + 16) * 3600 * 1000), "day");
}

export function safeStartOfLastWeek(startOfCurrentWeek: number, weekUnit: string): number {
  const day = new Date(startOfCurrentWeek);
  const noon = new Date(day.getFullYear(), day.getMonth(), day.getDate(), 12).valueOf() - 7 * 24 * 3600 * 1000;
  return startOfDateTime2Int(new Date(noon), weekUnit);
}

export async function safeGetImageURL(
  storage: firebase.storage.Storage,
  uid: string,
  key: string
): Promise<string | null> {
  try {
    return await storage.ref(`user/${uid}/${key}`).getDownloadURL();
  } catch (error) {
    return null;
  }
}

export function isEnumeratableUnit(unit: GoalUnit | undefined | null): boolean {
  if (!unit) {
    return false;
  }
  return [
    GoalUnit.Step,
    GoalUnit.Item,
    GoalUnit.Book,
    GoalUnit.Chapter,
    GoalUnit.Course,
    GoalUnit.Session,
    GoalUnit.Class,
    GoalUnit.Video
  ].indexOf(unit) >= 0;
}

export const CategoryOrdering = [
  MainCategory.Self, // mind heart
  MainCategory.Health, // heart
  MainCategory.Family, // home
  MainCategory.Financial, // money
  MainCategory.Fun, // drawing
  MainCategory.Study, // book
  MainCategory.Career, // rocket
  MainCategory.Social, // handshake
];
