import React, { useEffect, useState } from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import { authState } from "rxfire/auth";
import { useSelector, useDispatch } from "react-redux";
import app, { refToUserData, refToUserTodos } from "./db";
import { Convert, UserInfoV1, TodosV2 } from "./models";

import { initializeGoals, selectGoals } from "./app/reducers/goals_reducer";
import { initializePriorities, selectPriorities } from "./app/reducers/priorities_reducer";
import { initializeProfile, loadProfile, selectProfile } from "./app/reducers/profile_reducer";

import { RouteNames } from "./constants";
import { logError, logInfo } from "./utils";

import "./app.sass";
import Menu from "./components/menu";
import { UserInfoProvider, UserPrioritiesProvider } from "./interfaces";
import { UserInfoPresenter, ProfilePresenter } from "./presenters";

const LOCAL_USER_DATA_KEY = "mindful_user_data";
const LOCAL_USER_TODOS_KEY = "mindful_user_todos";

window.app = app;

function App(props: { children: React.ReactNode[] } & RouteComponentProps) {
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<firebase.User | null>(null);
  const dispatch = useDispatch();
  const goals = useSelector(selectGoals);
  const priorities = useSelector(selectPriorities);
  const profile = useSelector(selectProfile);

  // load from local cache
  useEffect(() => {
    const goalsData = localStorage.getItem(LOCAL_USER_DATA_KEY);
    if (!goalsData) {
      return;
    }

    try {
      const localUserInfo = JSON.parse(goalsData) as UserInfoV1;
      if (!localUserInfo) {
        return;
      }
      dispatch(initializeGoals(localUserInfo));
    } catch (error) {
      logError(error);
    }

    const todosData = localStorage.getItem(LOCAL_USER_TODOS_KEY);
    if (!todosData) {
      return;
    }

    try {
      const localTodos = JSON.parse(todosData) as TodosV2;
      if (!localTodos) {
        return;
      }
      dispatch(initializePriorities(localTodos));
    } catch (error) {
      logError(error);
    }
  }, [dispatch]); // important

  // subscribe to auth change
  useEffect(() => {
    authState(app.auth()).subscribe((u) => {
      setLoading(false);
      if (u) {
        if (!user) {
          setUser(u);
          dispatch(
            loadProfile({
              uid: u.uid,
              displayName: u.displayName,
              defaultProfilePicture: u.photoURL,
            })
          );
        }
      } else {
        dispatch(initializeProfile(null));
        navigate(RouteNames.SignIn);
      }
    });
  }, [user, dispatch]);

  // subscribe to goals and priorities if user is initialized
  useEffect(() => {
    if (user) {
      logInfo("subscribe goals");
      const goalsSubscription = refToUserData(user.uid).subscribe(async (userData) => {
        if (userData && userData.user) {
          localStorage.setItem(LOCAL_USER_DATA_KEY, Convert.userInfoV1ToJson(userData.user));
          dispatch(initializeGoals(userData.user));
        }
      });

      logInfo("subscribe todos");
      const prioritiesSubscription = refToUserTodos(user.uid).subscribe(async (userData) => {
        if (userData && userData.todos) {
          localStorage.setItem(LOCAL_USER_TODOS_KEY, Convert.todosV2ToJson(userData.todos));
          dispatch(initializePriorities(userData.todos));
        }
      });

      return () => {
        goalsSubscription.unsubscribe();
        prioritiesSubscription.unsubscribe();
      };
    }
  }, [user, dispatch]);

  const profilePresenter = profile
    ? new ProfilePresenter(profile.displayName, profile.customProfilePicture || profile.defaultProfilePicture)
    : undefined;

  const userInfo = goals ? new UserInfoPresenter(goals, profilePresenter) : undefined;

  return (
    <div className="App">
      <div className="App-menu">
        <Menu profile={profilePresenter} />
      </div>
      <div className="App-body">
        {!loading && userInfo && (
          <UserInfoProvider value={userInfo}>
            <UserPrioritiesProvider value={priorities}>
              <>{props.children}</>
            </UserPrioritiesProvider>
          </UserInfoProvider>
        )}
        <div className="App-footer">
          <div className="wrapper"></div>
        </div>
      </div>
    </div>
  );
}

export default App;
