import { logError, startOfDateTime2Int, endOfDateTime2Int } from "./utils";
import { MediaV1, CheckInPayloadV2, UncheckInPayloadV2, ObjectType, UpdateOrderingPayload } from "./models";
import firebase from "firebase/app";

export const API_HOST = "https://api.mindfulsuite.com";

export interface CheckInToHabitParams {
  habitId: string;
  disableCapture?: boolean | null;
  note?: string | null;
  media?: MediaV1 | null;
  date: number;
}

export class Api {
  static async ensureIdToken(): Promise<string | undefined> {
    if (!firebase.auth().currentUser) {
      return;
    }
    try {
      return await firebase.auth().currentUser?.getIdToken();
    } catch (error) {
      logError(error);
    }
  }

  static async post(
    path: string,
    params: {
      [id: string]: any
    }
  ): Promise<any> {
    try {
      const token = await this.ensureIdToken();
      if (!token) {
        return;
      }
      const response = await fetch(
        `${API_HOST}${path}`,
        {
          method: "POST", headers: {
            "X-Mindful-Token": token,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(params),
        },
      );
      const json = await response.json();
      return json;
    } catch (error) {
      return { "ok": false };
    }
  }

  static async checkInToHabit(params: CheckInToHabitParams) {
    try {
      const result = await this.post(
        "/checkInHabitV2",
        {
          habitId: params.habitId,
          startOfDay: startOfDateTime2Int(new Date(params.date ?? new Date()), "day"),
          endOfDay: endOfDateTime2Int(new Date(params.date ?? new Date()), "day"),
          disableCapture: params.disableCapture,
          media: params.media,
        } as CheckInPayloadV2,
      );
      return result["ok"];
    } catch (e) {
      logError(e);
      return null;
    }
  }

  static async uncheckInToHabit(habitId: string, date: number) {
    try {
      const result = await this.post(
        "/uncheckInHabitV2",
        {
          habitId: habitId,
          startOfDay: startOfDateTime2Int(new Date(date ?? new Date()), "day"),
          endOfDay: endOfDateTime2Int(new Date(date ?? new Date()), "day"),
        } as UncheckInPayloadV2,
      );
      return result["ok"];
    } catch (e) {
      logError(e);
      return null;
    }
  }

  static async updateHabitOrdering(newOrdering: string[]) {
    try {
      const result = await this.post(
        "/updateHabitOrdering",
        {
          type: ObjectType.HabitV1,
          newOrdering,
        } as UpdateOrderingPayload,
      );
      return result["ok"];
    } catch (e) {
      logError(e);
      return null;
    }
  }

  static async updateGoalOrdering(newOrdering: string[]) {
    try {
      const result = await this.post(
        "/updateGoalOrdering",
        {
          type: ObjectType.GoalV1,
          newOrdering,
        } as UpdateOrderingPayload,
      );
      return result["ok"];
    } catch (e) {
      logError(e);
      return null;
    }
  }
}
