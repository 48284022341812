import { ColorTheme, HabitV1, GoalV1, AccomplishmentV1, MindsetV1, UserInfoV1 } from "./models";
import { HabitStats } from "./habit_stats";
import { safeHabits, safeGoals, safeAccomplishments, safeMindses, goalPercentCompletion } from "./utils";

export class HabitPresenter {
  subject?: string | null;
  color?: ColorTheme | null;
  statsCompletion?: number | null;
  statsFrequencySummary?: string | null;
  stats?: HabitStats | null;
  habit?: HabitV1 | null;
  constructor(habit: HabitV1 | undefined = undefined) {
    if (habit) {
      this.habit = habit;
      this.subject = habit.subject;
      this.color = habit.color;
      this.stats = new HabitStats(habit);
      this.statsCompletion = this.stats.completion;
      this.statsFrequencySummary = this.stats.frequencySummary;
    }
  }

  toJSON(): { [id: string]: any } {
    const result: { [id: string]: any } = {};
    if (this.subject) {
      result.subject = this.subject;
    }
    if (this.color) {
      result.color = this.color;
    }
    if (this.statsCompletion) {
      result.statsCompletion = this.statsCompletion;
    }
    if (this.statsFrequencySummary) {
      result.statsFrequencySummary = this.statsFrequencySummary;
    }
    return result;
  }

  static fromJSON(map: { [id: string]: any }): HabitPresenter {
    const result = new HabitPresenter();
    result.subject = map.subject;
    result.color = map.color;
    result.statsCompletion = map.statsCompletion;
    result.statsFrequencySummary = map.statsFrequencySummary;
    return result;
  }
}

export class GoalPresenter {
  subject?: string | null;
  completionPercent?: number | null;
  color?: ColorTheme | null;
  goal?: GoalV1 | null
  constructor(goal: GoalV1 | undefined = undefined) {
    if (goal) {
      this.goal = goal;
      this.subject = goal.subject;
      this.color = goal.color;
      this.completionPercent = goalPercentCompletion(goal);
    }
  }

  toJSON(): { [id: string]: any } {
    const result: { [id: string]: any } = {};
    if (this.subject) {
      result.subject = this.subject;
    }
    if (this.color) {
      result.color = this.color;
    }
    if (this.completionPercent) {
      result.completionPercent = this.completionPercent;
    }
    return result;
  }

  static fromJSON(map: { [id: string]: any }): GoalPresenter {
    const result = new GoalPresenter();
    result.subject = map.subject;
    result.completionPercent = map.completionPercent;
    result.color = map.color;
    return result;
  }
}

export class MindsetPresenter {
  content?: string | null;
  url?: string | null;
  mindset?: MindsetV1 | null;
  constructor(mindset: MindsetV1 | undefined = undefined) {
    if (mindset) {
      this.mindset = mindset;
      this.content = mindset.content;
      this.url = mindset.background?.url;
    }
  }

  toJSON(): { [id: string]: any } {
    const result: { [id: string]: any } = {};
    if (this.content) {
      result.content = this.content;
    }
    if (this.url) {
      result.url = this.url;
    }
    return result;
  }

  static fromJSON(map: { [id: string]: any }): MindsetPresenter {
    const result = new MindsetPresenter();
    result.content = map.content;
    result.url = map.url;
    return result;
  }
}

export class AccomplishmentPresenter {
  content?: string | null;
  url?: string | null;
  accomplishment?: AccomplishmentV1 | null;
  constructor(accomplishment: AccomplishmentV1 | undefined = undefined) {
    if (accomplishment) {
      this.accomplishment = accomplishment;
      this.content = accomplishment.content;
      this.url = accomplishment.background?.url;
    }
  }

  toJSON(): { [id: string]: any } {
    const result: { [id: string]: any } = {};
    if (this.content) {
      result.content = this.content;
    }
    if (this.url) {
      result.url = this.url;
    }
    return result;
  }

  static fromJSON(map: { [id: string]: any }): AccomplishmentPresenter {
    const result = new AccomplishmentPresenter();
    result.content = map.content;
    result.url = map.url;
    return result;
  }
}

export class ProfilePresenter {
  displayName?: string | null;
  profileURL?: string | null;
  constructor(displayName: string | undefined | null, profileURL: string | undefined | null) {
    this.displayName = displayName;
    this.profileURL = profileURL;
  }

  toJSON(): { [id: string]: any } {
    const result: { [id: string]: any } = {};
    if (this.displayName) {
      result.displayName = this.displayName;
    }
    if (this.profileURL) {
      result.profileURL = this.profileURL;
    }
    return result;
  }

  static fromJSON(map: { [id: string]: any }): ProfilePresenter {
    return new ProfilePresenter(map.displayName, map.profileURL);
  }
}

export class UserInfoPresenter {
  habits: HabitPresenter[];
  goals: GoalPresenter[];
  mindsets: MindsetPresenter[];
  accomplishments: AccomplishmentPresenter[];
  profile?: ProfilePresenter;
  constructor(userInfo: UserInfoV1 | undefined = undefined, profile: ProfilePresenter | undefined = undefined) {
    if (userInfo) {
      const habits = userInfo ? safeHabits(userInfo.habit_ordering, userInfo.habits) : [];
      this.habits = habits.map((h) => new HabitPresenter(h));

      const goals = safeGoals(userInfo?.goal_ordering, userInfo?.goals);
      this.goals = goals.map((g) => new GoalPresenter(g));

      const accomplishments = userInfo
        ? safeAccomplishments(userInfo.accomplishment_ordering, userInfo.accomplishments)
        : [];
      this.accomplishments = accomplishments.slice(0, 1).map((a) => new AccomplishmentPresenter(a));

      const mindsets = userInfo ? safeMindses(userInfo.mindset_ordering, userInfo.mindsets) : [];
      this.mindsets = mindsets.map((m) => new MindsetPresenter(m));

      this.profile = profile;
    } else {
      this.habits = [];
      this.goals = [];
      this.mindsets = [];
      this.accomplishments = [];
    }
  }

  toJSON(): { [id: string]: any } {
    const result: { [id: string]: any } = {
      habits: this.habits.map((h) => h.toJSON()),
      goals: this.goals.map((g) => g.toJSON()),
      mindsets: this.mindsets.map((m) => m.toJSON()),
      accomplishments: this.accomplishments.map((a) => a.toJSON()),
    };

    if (this.profile) {
      result.profile = this.profile.toJSON();
    }

    return result;
  }

  static fromJSON(map: { [id: string]: any }): UserInfoPresenter {
    const result = new UserInfoPresenter();
    result.habits = (map.habits || []).map((m: { [id: string]: any }) => HabitPresenter.fromJSON(m));
    result.goals = (map.goals || []).map((m: { [id: string]: any }) => GoalPresenter.fromJSON(m));
    result.mindsets = (map.mindsets || []).map((m: { [id: string]: any }) => MindsetPresenter.fromJSON(m));
    result.accomplishments = (map.accomplishments || []).map((m: { [id: string]: any }) =>
      AccomplishmentPresenter.fromJSON(m)
    );
    result.profile = map.profile ? ProfilePresenter.fromJSON(map.profile) : undefined;
    return result;
  }
}
