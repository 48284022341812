import React from "react";
import { RouteComponentProps } from "@reach/router";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import "./dashboard.sass";
import Priorities from "./priorities";

export default function Dashboard({}: RouteComponentProps) {
  return (
    <div className="dashboard">
      <Priorities />
    </div>
  );
}
