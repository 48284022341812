import React, { MouseEvent, useState } from "react";
import chroma from "chroma-js";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps } from "@reach/router";
import { useDispatch } from "react-redux";
import green from "@material-ui/core/colors/green";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Fade from "@material-ui/core/Fade";
import DoneIcon from "@material-ui/icons/Done";
import UndoIcon from "@material-ui/icons/Undo";
import AnalyticsIcon from "@material-ui/icons/InsertChart";
import EditIcon from "@material-ui/icons/Edit";

import { ColorTheme } from "../models";
import { ColorThemeToSwatch } from "../utils";
import { HabitStats } from "../habit_stats";
import { UserInfoConsumer, WithUserInfo } from "../interfaces";
import { HabitPresenter } from "../presenters";
import { checkInToHabit, uncheckInToHabit, updateHabitOrdering } from "../app/reducers/goals_reducer";
import { CheckInToHabitParams } from "../api";
import { SortableContainer, SortableElement, SortEnd } from "react-sortable-hoc";
import "./habits.sass";

class HabitSummary {
  static progressSummary(habitStats?: HabitStats | null) {
    if (!habitStats) {
      return "";
    }

    const checkInsToGo = habitStats.checkInsToGo;
    const target = habitStats.targetFrequency;

    if (checkInsToGo > 0) {
      const daysLeft = habitStats.daysLeft;
      return (
        <div>
          <div>
            <span>
              <FormattedMessage id="plural.x_times_left_in_n_days.pre" defaultMessage=" " />
            </span>
            <em>
              <FormattedMessage id="plural.check_ins" defaultMessage="{checkIn}x" values={{ checkIn: checkInsToGo }} />
            </em>
            <span>
              <FormattedMessage
                id="plural.x_times_left_in_n_days.middle"
                defaultMessage="{nDays, plural, =0 { left } other { left in }}"
                values={{ nDays: daysLeft }}
              />
            </span>
          </div>
          <div>
            <em>
              <FormattedMessage
                id="plural.x_times_left_in_n_days.n"
                defaultMessage="{nDays, plural, =0 {} other {#}}"
                values={{ nDays: daysLeft }}
              />
            </em>
            <span> </span>
            <span>
              <FormattedMessage
                id="plural.x_times_left_in_n_days.n_days"
                defaultMessage="{nDays, plural, =0 {today} one {day} other {days}}"
                values={{ nDays: daysLeft }}
              />
            </span>
          </div>
        </div>
      );
    } else if (checkInsToGo === 0) {
      return <FormattedMessage id="habit_progress_complete" defaultMessage="You did it!" />;
    } else {
      return (
        <FormattedMessage
          id="habit_progress_extra"
          defaultMessage="{checkIns} + {extra} extra"
          values={{
            checkIns: (
              <FormattedMessage id="plural.check_ins" defaultMessage="{checkIn}x" values={{ checkIn: target }} />
            ),
            extra: (
              <FormattedMessage id="plural.check_ins" defaultMessage="{checkIn}x" values={{ checkIn: -checkInsToGo }} />
            ),
          }}
        />
      );
    }
  }
}

// Style help
// https://codepen.io/donovanh/pen/GgYEBZ

export function Habit({ habit }: { habit: HabitPresenter }) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const colorTheme = habit.color || ColorTheme.Blue;
  const swatch = ColorThemeToSwatch[colorTheme];
  const ratio = habit.statsCompletion || 0;
  const percent = (ratio * 100) | 0;
  const part1Rotation = ((180 * Math.min(percent, 50)) / 50) | 0;
  const part2Rotation = ((180 * Math.max(0, percent - 50)) / 50) | 0;

  const startColor = chroma(swatch.main).brighten(0.5).desaturate(0.4).hex();
  const finalColor = chroma(swatch.main).darken(0.5).saturate(0.4).hex();
  const endColor = chroma
    .scale([startColor, finalColor])(percent / 100.0)
    .hex();
  const middleColor = swatch.main;

  function openMenu(event: MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function closeMenu() {
    setAnchorEl(null);
  }

  function onCheckInToHabit() {
    closeMenu();
    dispatch(
      checkInToHabit({
        habitId: habit.habit?.id!,
        date: new Date().valueOf(),
      } as CheckInToHabitParams)
    );
  }

  function onUncheckInToHabit() {
    closeMenu();
    dispatch(uncheckInToHabit({ habitId: habit.habit?.id!, date: new Date().valueOf() }));
  }

  // if the habit is day task user can keep checking in.
  // otherwise, they can only check in if they haven't checked in today.
  const canCheckIn = habit.stats?.canContinue ? true : !habit.stats?.completedToday;

  // As long as users has checkIns today, they can uncheck in.
  const canUncheckIn = habit.stats?.checkedInToday;

  return (
    <>
      <div className={`habits__wrapper ${anchorEl ? "menu-open" : ""}`} onClick={openMenu}>
        <div className={`habits__habit ${"black" || habit.color || "orange"} `}>
          <div className="dials">
            <div className="dial move">
              <div className="dial-background one"></div>
              <div className="marker start" style={{ background: startColor }}></div>
              <div className="dial-container container1" style={{ transform: `rotateZ(${-180 + part1Rotation}deg)` }}>
                <div
                  className="wedge"
                  style={{
                    transform: `rotateZ(${-part1Rotation}deg)`,
                    background: `conic-gradient(from 180deg, ${startColor}, ${middleColor} 180deg, ${middleColor}00 180deg, ${middleColor}00 360deg)`,
                  }}
                ></div>
              </div>
              <div className="dial-container container2" style={{ transform: `rotateZ(${part2Rotation - 180}deg)` }}>
                <div
                  className="wedge"
                  style={{
                    transform: `rotateZ(${180 - part2Rotation}deg)`,
                    background: `conic-gradient(from 0deg, ${middleColor}00 0deg, ${middleColor}00, ${middleColor}00 180deg, ${middleColor} 180deg, ${finalColor} 360deg)`,
                  }}
                ></div>
              </div>
              <div
                className="marker end"
                style={{ transform: `rotateZ(${part1Rotation + part2Rotation}deg)`, background: endColor }}
              ></div>
            </div>
          </div>
          <div className="face">
            <span className="slug">
              <span>{HabitSummary.progressSummary(habit.stats)}</span>
            </span>
            <span className="subject">
              <h3>{habit.subject}</h3>
            </span>
            <span className="stats">
              <span className="stat">
                <span>
                  <FormattedMessage id="verbatim.streak_abbr" defaultMessage="STK" />
                </span>
                <em>{habit.stats?.weekStreak}</em>
              </span>
              <div className="sep"></div>
              <span className="stat">
                <span>
                  <FormattedMessage id="verbatim.average_abbr" defaultMessage="AVG" />
                </span>
                <em>{habit.stats?.averagePercent}</em>
              </span>
            </span>
          </div>
          {habit.stats?.completedToday && (
            <div className="checkmark">
              <DoneIcon style={{ color: green.A700 }} />
            </div>
          )}
        </div>
      </div>
      <Menu
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        transitionDuration={125}
        TransitionComponent={Fade}
      >
        {canCheckIn && (
          <MenuItem onClick={onCheckInToHabit}>
            <ListItemIcon>
              <DoneIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Check in" />
          </MenuItem>
        )}
        {canUncheckIn && (
          <MenuItem onClick={onUncheckInToHabit}>
            <ListItemIcon>
              <UndoIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Undo Check-in" />
          </MenuItem>
        )}
        {/* <MenuItem divider>
          <ListItemIcon>
            <AnalyticsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Analytics" />
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Edit" />
        </MenuItem> */}
      </Menu>
    </>
  );
}

const SortableItem = SortableElement(({ value }: { value: React.ReactElement }) => value);

const SortableGrid = SortableContainer(({ items }: { items: HabitPresenter[] }) => {
  return (
    <div className="habits__list">
      {items.map((habit, index) => (
        <SortableItem
          key={`habit-${habit.habit!.id}`}
          index={index}
          value={<Habit key={habit.habit!.id!} habit={habit} />}
        />
      ))}
    </div>
  );
});

function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

export function HabitList(props: { habits: HabitPresenter[] }) {
  const dispatch = useDispatch();

  function onDragEnd({ oldIndex, newIndex }: SortEnd) {
    const newHabits = reorder(habits, oldIndex, newIndex);
    dispatch(updateHabitOrdering(newHabits.filter((h) => Boolean(h.habit?.id)).map((h) => h.habit!.id!)));
  }

  const habits = props.habits;
  return <SortableGrid axis="xy" distance={25} onSortEnd={onDragEnd} items={habits} />;
}

export default function Habits(props: WithUserInfo & RouteComponentProps) {
  return (
    <UserInfoConsumer>
      {(userInfo) => {
        const info = props.userInfo ?? userInfo;
        return (
          <div className="wrapper">
            <div className="habits-section">
              <h2>Habits</h2>
              <div className="habits">
                <HabitList habits={info?.habits ?? []} />
              </div>
            </div>
          </div>
        );
      }}
    </UserInfoConsumer>
  );
}
